import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Stack,
  Grid,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  LocationOption,
  AudienceOption,
  ProductCategoryOption,
  ColorThemeOption,
  ColorStyleOption,
  ContrastLevelOption,
  AlignmentSchemeOption,
  ColorSchemaOption,
  FontSchemeOption,
} from "../../../models/data/options/common"


const paramGroupList = [

  {
    title: "General",
    list: [
      {
        title: "Location",
        key: "location",
        option: LocationOption,
      }, {
        title: "Product Category",
        key: "category",
        option: ProductCategoryOption,
      }, {
        title: "Audience",
        key: "audience",
        option: AudienceOption,
      }
    ]
  }, {
    title: "Color/Contrast (opt)",
    list: [
      {
        title: "Color Scheme",
        key: "colorSchema",
        option: ColorSchemaOption,
      }, {
        title: "Color Theme",
        key: "colorTheme",
        option: ColorThemeOption,
      }, {
        title: "Contrast Level",
        key: "contrast",
        option: ContrastLevelOption,
      }, {
        title: "Header Style",
        key: "headerStyle",
        option: ColorStyleOption,
      }, {
        title: "Footer Style",
        key: "footerStyle",
        option: ColorStyleOption,
      }
    ]
  }, {
    title: "Font/Alignment (opt)",
    list: [
      {
        title: "Font Scheme",
        key: "fontScheme",
        option: FontSchemeOption,
      }, {
        title: "Alignment",
        key: "alignment",
        option: AlignmentSchemeOption,
      }
    ]
  }


];

export function CommonParamList(props) {

  return (
    <>
      {
        paramGroupList.map((group, i) => {
          return (
            <Stack key={`group-${i}`}>
              <Typography variant="h6" sx={{ mb: 1 }}>{group.title}</Typography>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                {
                  group.list.map((item, j) => {
                    return (

                      <Grid item key={`item-${j}`}>

                        <FormControl sx={{ m: 0, minWidth: 150 }}>
                          <InputLabel shrink htmlFor={`option-{item.key}`}>{item.title}</InputLabel>
                          <Select
                            label={item.title}
                            value={props.meta[item.key] || ""}
                            sx={{ minWidth: 150 }}
                            onChange={(e) => {
                              props.onChange(item.key, e.target.value)
                            }}

                            inputProps={{
                              id: `option-{item.key}`,
                            }}
                          >
                            {
                              item.option.map((el, k) => {
                                return <MenuItem key={`option-${k}`} value={el.k || ""}>{el.t}</MenuItem>
                              })
                            }
                          </Select>
                        </FormControl>
                      </Grid>

                    )

                  })
                }
              </Grid>
            </Stack>
          )
        })
      }
    </>
  );
}
