import "./style";
import "@fontsource/material-icons";
//import "@fontsource/material-icons-rounded";
//import "@fontsource/material-icons-outlined";
//import "@fontsource/material-icons-sharp";
//import "@fontsource/material-icons-two-tone";

import { RecoilRoot } from "recoil";
import App from "./components/app";
import Logger from "js-logger";

const Root = () => {
  Logger.useDefaults({
    defaultLevel: Logger.DEBUG,
    formatter(messages, ctx) {
      messages.unshift(
        new Date().toISOString(),
        `[${ctx.level.name}]`,
        `[${ctx.name}]`
      );
    },
  });
  return (
    <RecoilRoot>
      <App />
    </RecoilRoot>
  );
};

export default Root;
