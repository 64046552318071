import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "preact/compat";

export function TreeBreadcrumb(props) {
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    const screen = props.screen;
    let id = props.id;
    let itemList = [];
    while (id) {
      itemList.unshift({
        id,
        name: screen?.getNode(id)?.name,
      });
      id = screen?.getParentId(id);
    }
    setItemList(itemList);
  }, [props]);

  return (
    <Breadcrumbs aria-label="breadcrumb" separator=">">
      {itemList.map((item, index) => {
        let out;
        if (index < itemList.length - 1) {
          out = (
            <Link
              component="button"
              onClick={(e) => {
                props.onSelect && props.onSelect(item.id);
                e.preventDefault();
              }}
            >
              {item.name || "-"}
            </Link>
          );
        } else {
          out = (
            <Typography color="text.secondary">{item.name || "-"}</Typography>
          );
        }
        return out;
      })}
    </Breadcrumbs>
  );
}
