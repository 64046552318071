import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

export function GridActionList(props) {
  return (
    <>
      <ButtonGroup variant="outlined" size="small">
        <LoadingButton
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            props.onReload && props.onReload(() => {});
          }}
        >
          <RotateLeftIcon />
        </LoadingButton>
      </ButtonGroup>
    </>
  );
}
