import { Typography, Stack } from "@mui/material";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";

export default function Rating(props) {
  const starColor = props.palette[2] || "rgb(255, 0 , 0)";

  const testFontSize = parseFloat(props.fontSize, 10);
  const iconFontSize =
    props.height * 5 < props.width
      ? Math.min(props.height, testFontSize)
      : Math.floor(props.width / 5);

  const isShowAdditionalText = props.height * 5 < props.width;

  return (
    <Stack
      sx={{
        color: props.color || "rgb(0,0,0)",
        fontFamily: props.fontFamily || "",
        textAlign: props?.textAlign || "left",
      }}
      spacing={2}
      direction="row"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack direction="row">
        <StarOutlinedIcon
          sx={{
            color: starColor,
            fontSize: iconFontSize,
          }}
        />
        <StarOutlinedIcon
          sx={{
            color: starColor,
            fontSize: iconFontSize,
          }}
        />
        <StarOutlinedIcon
          sx={{
            color: starColor,
            fontSize: iconFontSize,
          }}
        />
        <StarHalfOutlinedIcon
          sx={{
            color: starColor,
            fontSize: iconFontSize,
          }}
        />
        <StarOutlineOutlinedIcon
          sx={{
            color: starColor,
            fontSize: iconFontSize,
          }}
        />
      </Stack>

      {isShowAdditionalText && (
        <Typography
          sx={{
            color: props.color || "rgb(0,0,0)",
            fontSize: `${testFontSize}px`,
          }}
        >
          (3.5)
        </Typography>
      )}
      {false && isShowAdditionalText && (
        <Typography
          sx={{
            color: props.color || "rgb(0,0,0)",
            fontSize: `${testFontSize}px`,
          }}
        >
          Write a Review
        </Typography>
      )}
    </Stack>
  );
}
