import { Typography, Stack, Input } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function Quantity(props) {
  const isSimple = props.height * 3.5 > props.width;
  const inputStyle = {
    border: "1px solid black",
    backgroundColor: "#fff",
    height: "100%",
  };
  return (
    <Stack
      sx={{
        color: props.color || "rgb(0,0,0)",
        fontFamily: props.fontFamily || "",
        textAlign: props?.textAlign || "left",
        height: props?.height || "auto",
      }}
      spacing={1}
      direction="row"
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      {isSimple && <Input sx={inputStyle} value={1} />}
      {!isSimple && (
        <>
          <Typography
            sx={{
              color: props.color || "rgb(0,0,0)",
            }}
          >
            Qty:
          </Typography>
          <AddCircleOutlineIcon sx={{ cursor: "pointer" }} />
          <Input sx={inputStyle} value={1} />
          <RemoveCircleOutlineIcon sx={{ cursor: "pointer" }} />
        </>
      )}
    </Stack>
  );
}
