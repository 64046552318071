import { useState } from "preact/compat";
import { Popover, Button, Box, List, ListItem } from "@mui/material";

export function ElementParamList(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button aria-describedby={id} variant="outlined" onClick={handleClick}>
        box params
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List
          sx={{
            margin: 2,
            width: 400,
          }}
        >
          {Object.keys(props.params || {}).map((k, i) => {
            return (
              <ListItem key={i}>
                {k} = {props.params[k] || "null"}
                {k.indexOf("color") !== -1 && (
                  <Box
                    sx={{
                      display: "inline-block",
                      backgroundColor: props.params[k],
                      width: "12px",
                      height: "12px",
                      border: "1px solid black",
                      margin: "-3px 0px 0px 4px",
                    }}
                  />
                )}
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
}
