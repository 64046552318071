import ServiceContext from "../../components/providers/service";
import { useContext, useEffect, useState } from "preact/compat";
import { route } from "preact-router";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import ArticleIcon from "@mui/icons-material/Article";
import { ListActionList } from "./components/listActionList";
import ListFilter from "./components/listFilter";
import Stack from "@mui/material/Stack";
import { GridActionList } from "./components/gridActionList";

const PageList = (props) => {
  const { api } = useContext(ServiceContext);

  const [page, setPage] = useState(parseInt(props.page || 1, 10));
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowList, setRowList] = useState([]);
  const [filter, setFilter] = useState(null);

  const reloadData = (api, page, filter) => {
    if (api && api.state == "connected" && filter) {
      route(
        `${page == 1 ? "/pages" : `/pages/${page}`}${window.location.search}`
      );
      setIsLoading(true);
      api.action("pageList", { page: page || 1, filter }, (data) => {
        setRowList(data.list || []);
        setRowCount(data.pager.total);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    reloadData(api, page, filter);
  }, [api, api?.state, page, filter]);

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4">Page List</Typography>
        <GridActionList
          onReload={(cb) => {
            reloadData(api, page, filter);
            cb && cb();
          }}
        />
      </Stack>
      <ListFilter
        onChange={(f, isResetPager) => {
          setFilter(f);
          isResetPager && setPage(1);
        }}
      />
      <Box sx={{ height: window.innerHeight - 210 }}>
        <DataGrid
          initialState={{
            pagination: {
              page: page - 1,
              pageSize: 20,
            },
          }}
          page={page - 1}
          rowsPerPageOptions={[20]}
          rowCount={rowCount}
          paginationMode="server"
          loading={isLoading}
          onPageChange={(p) => {
            setPage(p + 1);
          }}
          filterMode="server"
          sortMode="server"
          columns={[
            {
              field: "hasModel",
              filterable: false,
              hideable: false,
              minWidth: 20,
              renderCell: (params) => {
                return params.value ? <ArticleIcon /> : <></>;
              },
            },
            {
              field: "id",
              filterable: false,
              hideable: false,
              minWidth: 250,
              renderCell: (params) => (
                <Button
                  sx={{ textTransform: "none" }}
                  href={`/page/${params.value}`}
                >
                  {params.value}
                </Button>
              ),
            },
            {
              field: "target",
              minWidth: 120,
              filterable: false,
              hideable: false,
            },
            { field: "type", minWidth: 50, filterable: false, hideable: false },
            {
              field: "address",
              flex: 1,
              minWidth: 150,
              filterable: false,
              hideable: false,
            },
            {
              field: "createdAt",
              minWidth: 210,
              filterable: false,
              hideable: false,
            },
            {
              field: "action",
              filterable: false,
              hideable: false,
              minWidth: 200,
              renderCell: (params) => (
                <ListActionList
                  onDelete={(cb) => {
                    api.action("pageDelete", { id: params.id }, (data) => {
                      cb && cb();
                      if (data.ok) {
                        setRowList(
                          rowList.filter((row) => {
                            return row.id !== params.id;
                          })
                        );
                      }
                    });
                  }}
                  onSessionCreate={(cb) => {
                    api.action(
                      "sessionCreate",
                      {
                        target: params?.row?.target,
                        url: params?.row?.address,
                      },
                      (data) => {
                        cb && cb();
                        if (data.ok && data.id) {
                          route(`/session/${data.id}`);
                        }
                      }
                    );
                  }}
                />
              ),
            },
          ]}
          rows={rowList}
        />
      </Box>
    </div>
  );
};

export default PageList;
