import ServiceContext from "../../components/providers/service";
import { useContext, useEffect, useState } from "preact/compat";
import { route } from "preact-router";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { GridActionList } from "./components/gridActionList";
import { ListActionList } from "./components/listActionList";
import CreateFormDialog from "./components/createFormDialog";

const SessionList = (props) => {
  const { api } = useContext(ServiceContext);

  const [page, setPage] = useState(parseInt(props.page || 1, 10));
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowList, setRowList] = useState([]);

  useEffect(() => {}, []);

  const reloadData = (api, page) => {
    if (api && api.state == "connected") {
      setIsLoading(true);
      api.action("sessionList", { page: page || 1 }, (data) => {
        setRowList(data.list || []);
        setRowCount(data.pager.total);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    reloadData(api, page);
    // if (api && api.state == "connected") {
    //   setIsLoading(true);
    //   api.action("sessionList", { page: page || 1 }, (data) => {
    //     setRowList(data.list || []);
    //     setRowCount(data.pager.total);
    //     setIsLoading(false);
    //   });
    // }
  }, [api, api?.state, page]);

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4">Grabber Session List</Typography>
        <GridActionList
          onReload={(cb) => {
            reloadData(api, page);
            cb && cb();
          }}
        />
      </Stack>
      <Box sx={{ height: window.innerHeight - 200 }}>
        <DataGrid
          initialState={{
            pagination: {
              page: page - 1,
              pageSize: 20,
            },
          }}
          rowsPerPageOptions={[20]}
          rowCount={rowCount}
          paginationMode="server"
          loading={isLoading}
          onPageChange={(page) => {
            route(page == 0 ? "/sessions" : `/sessions/${page + 1}`);
            setPage(page + 1);
          }}
          filterMode="server"
          sortMode="server"
          columns={[
            {
              field: "id",
              minWidth: 250,
              renderCell: (params) => (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {params.row.isLocked && <CircularProgress size={15} />}
                  <Button
                    sx={{ textTransform: "none" }}
                    href={`/session/${params.value}`}
                  >
                    {params.value}
                  </Button>
                </Stack>
              ),
            },
            { field: "target", minWidth: 120 },
            { field: "address", flex: 1, minWidth: 150 },
            {
              field: "action",
              minWidth: 200,
              renderCell: (params) => (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <ListActionList
                    onDelete={(cb) => {
                      api.action("sessionDelete", { id: params.id }, (data) => {
                        cb && cb();
                        if (data.ok) {
                          setRowList(
                            rowList.filter((row) => {
                              return row.id !== params.id;
                            })
                          );
                        }
                      });
                    }}
                  />
                </Stack>
              ),
            },
          ]}
          rows={rowList}
        />
      </Box>
      <Grid container justifyContent="flex-end">
        <CreateFormDialog
          api={api}
          onCreate={(id, cb) => {
            if (id) {
              route(`/session/${id}`);
            } else {
              reloadData(api, 1);
            }
            cb && cb();
          }}
        />
      </Grid>
    </div>
  );
};

export default SessionList;
