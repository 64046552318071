export class PageDomManager {
  id;
  target;
  type;
  address;
  screenMeta;
  screenList = [];

  constructor(id, target, type, address, screenMeta) {
    this.id = id;
    this.target = target;
    this.type = type;
    this.address = address;
    this.screenMeta = screenMeta;
  }

  setScreen(i, m) {
    this.screenList[i] = m;
    return m;
  }

  getScreen(i) {
    return this.screenList[i] || null;
  }

  getScreenCnt() {
    return this.screenMeta?.length || 0;
  }
}
