import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "preact/hooks";

export function DetailsActionList(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <>
      <ButtonGroup
        variant="outlined"
        size="large"
        sx={{ padding: "0 0 4px 0" }}
      >
        <LoadingButton
          variant="outlined"
          loading={isSaving}
          disabled={!!props.dm?.isUpdated() || isSaving || isDeleting}
          onClick={() => {
            setIsSaving(true);
            props.onSave &&
              props.onSave(() => {
                setIsSaving(false);
              });
          }}
        >
          save
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          color="success"
          disabled={!!props.dm?.isUpdated() || isSaving || isDeleting}
          onClick={() => {
            props.onReset && props.onReset(() => {});
          }}
        >
          reset
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          color="error"
          loading={isDeleting}
          disabled={isSaving || isDeleting}
          onClick={() => {
            setIsDeleting(true);
            props.onDelete &&
              props.onDelete(() => {
                setIsDeleting(false);
              });
          }}
        >
          clear
        </LoadingButton>
      </ButtonGroup>
    </>
  );
}
