import { atom } from "recoil";
import storageEffect from "./effects/storage";

// const pageDataItemListStateList = {};

// const pageDataItemListState = (id) => {
//   const key = `pdItemList/${id}`;
//   if (!pageDataItemListStateList[key]) {
//     pageDataItemListStateList[key] = atom({
//       key,
//       default: {},
//       effects: [storageEffect(key)],
//     });
//   }
//   return pageDataItemListStateList[key];
// };

// export default pageDataItemListState;

const key = `pdItemList`;

const pageDataItemListState = atom({
  key,
  default: {},
  effects: [storageEffect(key)],
});

export default pageDataItemListState;
