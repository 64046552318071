let _targetList = [
  {
    key: "bigcommerce",
    title: "Bigcommerce",
  },
  {
    key: "demandware",
    title: "Demandware (SFCC)",
  },
  {
    key: "magento",
    title: "Magento",
  },
  {
    key: "shopify",
    title: "Shopify",
  },
  {
    key: "woocommerce",
    title: "Woocommerce",
  },
  {
    key: "opencart",
    title: "OpenCart",
  },
  {
    key: "x-cart",
    title: "X-Cart",
  },
  {
    key: "zencart",
    title: "ZenCart",
  },
];
_targetList.sort((a, b) => {
  return a.key > b.key ? 1 : -1;
});

export const targetList = [..._targetList];

export const typeList = [
  {
    key: "sfp",
    title: "[SFP] Store Front",
  },
  {
    key: "cpp",
    title: "[CPP] Category Promo",
  },
  {
    key: "clp",
    title: "[CLP] Category Listing",
  },
  {
    key: "ppp",
    title: "[PPP] Product Promo",
  },
  {
    key: "pdp",
    title: "[PDP] Product Details",
  },
];

export const viewList = [
  {
    key: "design",
    title: "Design",
  },
  {
    key: "box",
    title: "Boxes",
  },
  {
    key: "hybrid",
    title: "Hybrid",
  },
]
