import { BasicDataManager } from "./basicManager";

export class PdpDataManager extends BasicDataManager {
  structure = [
    {
      key: "header",
      type: "box",
      required: true,
      children: [
        {
          key: "logo",
          type: "image",
          required: true,
          quantity: 1,
        },
        {
          key: "slogan",
          type: "text",
        },
        {
          key: "menuLink",
          type: "button",
          quantity: 1,
        },
        {
          key: "searchLink",
          type: "button",
          quantity: 1,
        },
        {
          key: "profileLink",
          type: "button",
          quantity: 1,
        },
        {
          key: "cartLink",
          type: "button",
          quantity: 1,
        },
        {
          key: "favoriteLink",
          type: "button",
          quantity: 1,
        },
      ],
    },
    {
      key: "product",
      type: "box",
      required: true,
      children: [
        {
          key: "breadcrumbs",
          type: "box",
        },
        {
          key: "picture",
          type: "image",
          required: true,
        },
        {
          key: "thumbnails",
          type: "box",
        },
        {
          key: "title",
          type: "text",
          required: true,
          quantity: 1,
        },
        {
          key: "code",
          type: "text",
        },
        {
          key: "category",
          type: "text",
        },
        {
          key: "tags",
          type: "box",
        },
        {
          key: "rating",
          type: "box",
        },
        {
          key: "price",
          type: "text",
          required: true,
          quantity: 1,
        },
        {
          key: "description",
          type: "box",
          required: true,
        },
        {
          key: "share",
          type: "box",
          quantity: 1,
        },
        {
          key: "addToFavorite",
          type: "button",
        },
        {
          key: "callToAction",
          type: "button",
          required: true,
          quantity: 1,
        },
        {
          key: "option",
          type: "box",
        },
        {
          key: "quantity",
          type: "box",
        },
      ],
    },
    {
      key: "footer",
      type: "box",
      children: [
        {
          key: "title",
          type: "text",
        },
        {
          key: "link",
          type: "button",
        },
        {
          key: "social",
          type: "box",
          quantity: 1,
        },
        {
          key: "subscribe",
          type: "box",
        },
        {
          key: "logo",
          type: "image",
        },
        {
          key: "copyright",
          type: "text",
          quantity: 1,
        },
        {
          key: "address",
          type: "text",
        },
        {
          key: "phone",
          type: "text",
        },
      ],
    },
    {
      key: "promo",
      type: "box",
      children: [
        {
          key: "text",
          type: "text",
        },
        {
          key: "image",
          type: "image",
        },
      ],
    },
  ];
}
