import { Stack, Typography, Box, Select, MenuItem } from "@mui/material";

export default function OptionList(props) {
  const optionCnt = Math.max(
    1,
    Math.floor(props?.height / ((props?.fontSize || 16) * 4))
  );

  const boxSize = Math.min(props.height, props.fontSize * 2);

  const optionListByType = {
    color: OptionColor,
    size: OptionSize,
    select: OptionSelect,
  };

  const currOptionList = [];

  for (let i = 0; i < optionCnt; i++) {
    currOptionList.push(
      optionListByType[props?.data?.provider?.getOptionType()] || null
    );
  }

  return (
    <Stack
      sx={{
        color: props.color || "rgb(0,0,0)",
        fontFamily: props.fontFamily || "",
        textAlign: props?.textAlign || "left",
        height: props?.height || "auto",
      }}
      spacing={1}
      direction="column"
      alignItems={"flex-start"}
      justifyContent={"space-around"}
    >
      {currOptionList.map((o, i) => {
        const Element = o;
        return <Element key={i} {...props} boxSize={boxSize} />;
      })}
    </Stack>
  );
}

function OptionSelect(props) {
  return (
    <Select
      sx={{ height: `${props.boxSize}px`, width: "100%", textAlign: "left" }}
      defaultValue="1"
    >
      <MenuItem value={1}>Option 1</MenuItem>
      <MenuItem value={2}>Option 2</MenuItem>
      <MenuItem value={3}>Option 3</MenuItem>
    </Select>
  );
}

const getRandomColor = () => {
  return `rgb(${Math.floor(Math.random() * 255)},${Math.floor(
    Math.random() * 255
  )},${Math.floor(Math.random() * 255)})`;
};

const OptionColor = (props) => {
  const colorList = [];
  for (let i = 0; i < 2 + Math.floor(Math.random() * 3); i++) {
    colorList.push(getRandomColor());
  }
  return (
    <Stack direction="row" alignItems={"center"} spacing={1}>
      <Typography>Color:</Typography>
      {colorList.map((color, i) => {
        return (
          <Box
            key={i}
            sx={{
              width: `${props.boxSize || 23}px`,
              height: `${props.boxSize || 23}px`,
              border: "1px solid black",
              backgroundColor: color,
            }}
          />
        );
      })}
    </Stack>
  );
};

const OptionSize = (props) => {
  const sizeList = [28, 32, 34, 36];
  return (
    <Stack direction="row" alignItems={"center"} spacing={1}>
      <Typography>Size:</Typography>
      {sizeList.map((size, i) => {
        return (
          <Box
            key={i}
            sx={{
              width: `${props.boxSize}px`,
              height: `${props.boxSize}px`,
              border: "1px solid black",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {size}
          </Box>
        );
      })}
    </Stack>
  );
};
