import { useEffect, useState } from "preact/compat";

import Box from "@mui/material/Box";
import styles from "../../page/details.css";

// elements importing
import NotImplemented from "./elements/noImplemented";
import IconMenu from "./elements/iconMenu";
import IconSearch from "./elements/iconSearch";
import IconCart from "./elements/iconCart";
import IconProfile from "./elements/iconProfile";
import IconFavorite from "./elements/iconFavorite";
import ImageLogo from "./elements/imageLogo";
import ImageProduct from "./elements/imageProduct";
import ImageProductThumbnail from "./elements/imageProductThumbnail";
import TextPrice from "./elements/textPrice";
import TextPromo from "./elements/textPromo";
import TextProductTitle from "./elements/textProductTitle";
import TextProductDescription from "./elements/textProductDescription";
import TextProductCode from "./elements/textProductCode";
import TextProductCallToAction from "./elements/textProductCallToAction";
import TextProductAddToFavorite from "./elements/textProductAddToFavorite";
import TextProductCategory from "./elements/textProductCategory";
import TextFooterTitle from "./elements/textFooterTitle";
import TextFooterCopyright from "./elements/textFooterCopyright";
import TextFooterLink from "./elements/textFooterLink";
import Breadcrumbs from "./elements/breadcrumbs";
import Rating from "./elements/rating";
import Social from "./elements/social";
import Share from "./elements/share";
//import SubscribeForm from "./elements/subscribeForm";
import Quantity from "./elements/quantity";
import OptionList from "./elements/optionList";
import TextTags from "./elements/textTags";

import DataProvider from "../dataset/pdpProvider";
import { Helmet } from "react-helmet";

const componentMap = {
    pdp: {
        "header:logo": [ImageLogo, { backgroundColor: "rgba(0,0,0,0)" }],
        "header:cartLink": [IconCart, { backgroundColor: "rgba(0,0,0,0)" }],
        "header:menuLink": [IconMenu, { backgroundColor: "rgba(0,0,0,0)" }],
        "header:searchLink": [IconSearch, { backgroundColor: "rgba(0,0,0,0)" }],
        "header:profileLink": [IconProfile, { backgroundColor: "rgba(0,0,0,0)" }],
        "header:favoriteLink": [IconFavorite, { backgroundColor: "rgba(0,0,0,0)" }],
        "header:slogan": TextPromo,
        "product:price": TextPrice,
        "product:breadcrumbs": Breadcrumbs,
        "product:title": TextProductTitle,
        "product:description": TextProductDescription,
        "product:code": TextProductCode,
        "product:rating": Rating,
        "product:picture": ImageProduct,
        "product:thumbnails": ImageProductThumbnail,
        "product:share": Share,
        "product:callToAction": TextProductCallToAction,
        "product:addToFavorite": TextProductAddToFavorite,
        "product:quantity": Quantity,
        "product:option": OptionList,
        "product:tags": TextTags,
        "product:category": TextProductCategory,
        "footer:logo": [ImageLogo, { backgroundColor: "rgba(0,0,0,0)" }],
        "footer:social": Social,
        //"footer:subscribe": SubscribeForm,
        "footer:sosial": [Social, { backgroundColor: "rgba(0,0,0,0)" }],
        "footer:title": TextFooterTitle,
        "footer:copyright": TextFooterCopyright,
        "footer:link": TextFooterLink,
        "promo:text": TextPromo,
        "promo:image": ImageProduct,

        "footer:subscribe": NotImplemented,
        "footer:address": NotImplemented,
        "footer:phone": NotImplemented,
    },
};

const boxColorList = {
    pdp: {
        "header": [255, 255, 255],
        "header:logo": [161, 201, 244],
        "header:slogan": [255, 180, 130],
        "header:menuLink": [141, 229, 161],
        "header:searchLink": [255, 159, 155],
        "header:profileLink": [208, 187, 255],
        "header:cartLink": [222, 187, 155],
        "header:favoriteLink": [250, 176, 228],
        "product": [255, 255, 255],
        "product:breadcrumbs": [207, 207, 207],
        "product:picture": [52, 174, 140],
        "product:thumbnails": [177, 134, 244],
        "product:title": [246, 112, 136],
        "product:code": [247, 115, 103],
        "product:category": [243, 121, 50],
        "product:tags": [202, 145, 49],
        "product:rating": [187, 151, 49],
        "product:price": [173, 156, 49],
        "product:description": [158, 161, 49],
        "product:share": [142, 165, 49],
        "product:addToFavorite": [119, 170, 49],
        "product:callToAction": [79, 176, 49],
        "product:option": [50, 177, 92],
        "product:quantity": [51, 176, 122],
        "footer": [255, 255, 255],
        "footer:title": [52, 173, 153],
        "footer:link": [171, 145, 68],
        "footer:social": [53, 172, 164],
        "footer:subscribe": [54, 171, 174],
        "footer:logo": [55, 170, 185],
        "footer:copyright": [56, 168, 197],
        "footer:address": [57, 166, 212],
        "footer:phone": [59, 163, 236],
        "promo": [255, 255, 255],
        "promo:text": [110, 154, 244],
        "promo:image": [148, 145, 244],
    },
};

const getFontList = (paramList) => {
    const fontMap = {};
    const fontFamilyTranslation = {
        cormorant_garamondregular: "Cormorant Garamond",
    };
    (paramList || []).map((param) => {
        // TODO: process all font-family?
        // temporary get only first
        const fontFamilyList = (param?.["font-family"] || "").split(",");
        if (fontFamilyList.length > 0) {
            let fontFamily = (fontFamilyList[0] || "").replace(
                /^["\s]+|["\s]+$/gm,
                ""
            );
            if (fontFamily) {
                fontFamily = fontFamilyTranslation[fontFamily] || fontFamily;
                const fontItalic = param?.["font-style"] === "italic" ? "1" : "0";
                const fontWeight = param?.["font-weight"] || "400";
                fontMap[fontFamily] = fontMap[fontFamily] || {};
                fontMap[fontFamily][fontWeight] = fontMap[fontFamily][fontWeight] || {};
                fontMap[fontFamily][fontWeight][fontItalic] = true;
            }
        }
    });
    let fontList = "Roboto";
    for (const fontFamily in fontMap) {
        fontList += `|${encodeURIComponent(fontFamily)}:ital,wght@`;
        for (const fontWeight in fontMap[fontFamily]) {
            for (const fontItalic in fontMap[fontFamily][fontWeight]) {
                fontList += `${fontItalic},${fontWeight};`;
            }
        }
    }
    return fontList;
};

const rgbaToString = (c) => {
    return c
        ? c.a === 1
            ? `rgb(${c.r},${c.g},${c.b})`
            : `rgba(${c.r},${c.g},${c.b},${c.a})`
        : null;
};

const getRgba = (color) => {
    let match = (color || '').match(
        /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d\.?\d{0,2})\))?/
    );
    return match
        ? {
            r: parseInt(match[1], 10),
            g: parseInt(match[2], 10),
            b: parseInt(match[3], 10),
            a: parseFloat(match[4] || 1, 10),
        }
        : {};
};

// https://www.compuphase.com/cmetric.htm
const rgbDistance = (c1, c2) => {
    const rmean = (c1.r + c2.r) / 2;
    const r = c1.r - c2.r;
    const g = c1.g - c2.g;
    const b = c1.b - c2.b;
    return Math.sqrt(
        (2 + rmean / 256) * r * r + 4 * g * g + (2 + (255 - rmean) / 256) * b * b
    );
};

const filterClosestRgb = (c, initList) => {
    let list = [...initList];
    list = list.sort((c1, c2) => {
        return rgbDistance(c1, c) < rgbDistance(c2, c) ? -1 : 1;
    });
    const closestC = list.shift();
    return initList.filter((e) => {
        return e !== closestC;
    });
};

const getColorList = (color, backgroundColor, palete) => {
    if (!palete) {
        return {
            background: null,
            primary: null,
            secondary: null,
            tertiary: null,
            quaternary: null,
        };
    }

    let initList = palete.map((c) => {
        return getRgba(c);
    });

    //initList.subst;

    let c = getRgba(color);
    let bg = getRgba(backgroundColor);

    if (c.a === 0 && bg.a === 0) {
        bg = initList.shift();
        c = initList.shift();
    } else if (c.a === 0) {
        initList = filterClosestRgb(bg, initList);
        c = initList.shift();
    } else if (bg.a === 0) {
        initList = filterClosestRgb(c, initList);
        bg = initList.shift();
    } else {
        initList = filterClosestRgb(bg, initList);
        // bg === c => seems color is wrong
        if (rgbDistance(c, bg) === 0) {
            c = initList.shift();
        } else {
            // sort by closest to color
            initList = filterClosestRgb(c, initList);
        }
    }

    return {
        background: rgbaToString(bg),
        primary: rgbaToString(c),
        secondary: rgbaToString(initList[0]),
        tertiary: rgbaToString(initList[1]),
        quaternary: rgbaToString(initList[2]),
    };
};

export function LayoutPreview(props) {
    const [data, setData] = useState();
    const [fontList, setFontList] = useState(null);

    useEffect(() => {
        const provider = new DataProvider();
        setData({
            provider,
            product: provider.getProduct(),
            logo: provider.getLogo(),
        });
    }, [props]);

    useEffect(() => {
        setFontList(getFontList(props?.boxParam));
    }, [props?.boxParam]);

    return (
        <>
            <Helmet>
                {fontList && (
                    <link
                        rel="stylesheet"
                        href={`//fonts.googleapis.com/css?family=${fontList}`}
                    />
                )}
            </Helmet>

            {data && (
                <Box
                    sx={{
                        width: `${props?.pageSize?.w}px`,
                        position: "relative",
                        minHeight: `${props?.pageSize?.h}px`,
                        overflowX: "hidden",
                        backgroundColor: "#fff",
                    }}
                    className={styles.layoutView}
                >
                    {props.box.map((box, i) => {
                        const params = {};

                        for (let k in props?.boxParam[i] || {}) {
                            params[toCamelCase(k)] = props?.boxParam[i][k];
                        }

                        // calculate zIndex
                        const hasParent =
                            props?.boxRelation.filter((rel) => {
                                return rel[1] === 2 && rel[0] === i;
                            }).length > 0;
                        let zIndex = hasParent ? 100 : 1;

                        if (hasParent && params?.zIndex) {
                            zIndex += params?.zIndex;
                        }


                        const padding = `${params.paddingTop ?? 0} ${params.paddingRight ?? 0
                            } ${params.paddingBottom ?? 0} ${params.paddingLeft ?? 0}`;

                        const textAlign = params?.textAlign || "start";

                        let justifyContent = "flex-start";
                        switch (textAlign) {
                            case "center":
                                justifyContent = "center";
                                break;
                            case "end":
                            case "right":
                                justifyContent = "flex-end";
                                break;
                        }

                        let Element = null;
                        let elementForceParams = {};

                        const componentMappingData = componentMap["pdp"][props?.boxType[i]];
                        if (Array.isArray(componentMappingData)) {
                            Element = componentMappingData[0];
                            elementForceParams = componentMappingData[1];
                        } else {
                            Element = componentMappingData || Box;
                            elementForceParams = {};
                        }

                        const palete = getColorList(
                            params.color,
                            params.backgroundColor,
                            params.palette
                        );

                        let boxProps = {};


                        let width = box[2] - box[0];
                        let height = box[3] - box[1];

                        switch (props.view) {
                            case "design":

                                let devBorder = 0;
                                width += 2 * devBorder
                                height += 2 * devBorder

                                boxProps = {
                                    position: "absolute",
                                    left: box[0] - devBorder,
                                    top: box[1] - devBorder,
                                    width,
                                    height,
                                    boxSizing: params.boxSizing,

                                    //border
                                    borderRadius: `${params.borderStartStartRadius || 0} ${params.borderStartEndRadius || 0
                                        } ${params.borderEndEndRadius || 0} ${params.borderEndStartRadius || 0
                                        }`,
                                    borderLeft:
                                        parseFloat(params.borderLeftSize, 10) > 0
                                            ? `${params.borderLeftSize} ${params.borderLeftStyle} ${params.borderLeftColor}`
                                            : null,
                                    borderTop:
                                        parseFloat(params.borderTopSize, 10) > 0
                                            ? `${params.borderTopSize} ${params.borderTopStyle} ${params.borderTopColor}`
                                            : null,
                                    borderRight:
                                        parseFloat(params.borderRightSize, 10) > 0
                                            ? `${params.borderRightSize} ${params.borderRightStyle} ${params.borderRightColor}`
                                            : null,
                                    borderBottom:
                                        parseFloat(params.borderBottomSize, 10) > 0
                                            ? `${params.borderBottomSize} ${params.borderBottomStyle} ${params.borderBottomColor}`
                                            : null,

                                    border: devBorder ? `${devBorder}px solid black; ` : null,

                                    cursor: params.cursor || "normal",
                                    overflow: "hidden",

                                    backgroundColor:
                                        elementForceParams.backgroundColor ||
                                        params.backgroundColor ||
                                        palete.background ||
                                        null,

                                    color: params.color || palete.primary || null,

                                    fontSize: params.fontSize,
                                    fontFamily: params.fontFamily,

                                    zIndex,

                                    opacity:
                                        params.opacity && params.opacity < 1
                                            ? params.opacity
                                            : null,
                                };
                                break;
                            case "hybrid":
                            case "box":
                                const c = boxColorList["pdp"][props?.boxType[i]];
                                const b = 2
                                boxProps = {
                                    position: "absolute",
                                    left: box[0],
                                    top: box[1],
                                    width,
                                    height,
                                    boxSizing: params.boxSizing,
                                    border: b ? `${b}px solid rgb(${c[0]},${c[1]},${c[2]}); ` : null,
                                    backgroundColor: `rgba(${c[0]},${c[1]},${c[2]},0.5)`, 
                                    overflow: "hidden",
                                }
                                break;
                        }

                        return (
                            <Box
                                key={i}
                                sx={boxProps}
                                onClick={() => {
                                    //console.log(palete, params);
                                    //console.log(props?.boxType[i], params);
                                }}
                            >
                                {["design", "hybrid"].indexOf(props.view) !== -1 && data && (
                                    <Element
                                        type={props?.boxType[i]}
                                        data={data}
                                        width={width}
                                        height={height}
                                        color={params.color || params.palette?.[0] || "#000"}
                                        fontSize={parseInt(params.fontSize, 10)}
                                        fontFamily={params.fontFamily}
                                        textAlign={textAlign}
                                        lineHeight={params?.lineHeight}
                                        justifyContent={justifyContent}
                                        palette={params.palette || []}
                                        margin={padding}
                                    />
                                )}
                            </Box>
                        );
                    })}
                </Box>
            )}
        </>
    );
}

const toCamelCase = (text) => {
    return text
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (leftTrim, index) =>
            index === 0 ? leftTrim.toLowerCase() : leftTrim.toUpperCase()
        )
        .replace(/[-\s]+/g, "");
};
