import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "preact/hooks";

export function DetailsActionList(props) {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <>
      <ButtonGroup
        variant="outlined"
        size="large"
        sx={{ padding: "0 0 4px 0" }}
      >
        <LoadingButton
          variant="outlined"
          color="error"
          loading={isDeleting}
          disabled={isDeleting}
          onClick={() => {
            setIsDeleting(true);
            props.onDelete &&
              props.onDelete(() => {
                setIsDeleting(false);
              });
          }}
        >
          delete
        </LoadingButton>
      </ButtonGroup>
    </>
  );
}
