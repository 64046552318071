import { useState, useEffect } from "preact/compat";
import { route } from "preact-router";
import {
  Button,
  ButtonGroup,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Stack,
  Box,
} from "@mui/material";
import qs from "qs";
import { targetList, typeList } from "../constant.js";

const defaultFilter = {
  hasModel: "",
  target: "",
  type: "pdp",
  address: "",
};

const ListFilter = (props) => {
  const [filter, setFilter] = useState(defaultFilter);

  const applyFilter = (f, isResetPage) => {
    let qsObj = { ...(f || filter) };
    Object.keys(qsObj).map((k) => {
      if (!qsObj[k]) {
        delete qsObj[k];
      }
    });
    const query = qs.stringify({
      filter: qsObj,
    });
    route(`${window.location.pathname}?${query}`);
    props.onChange && props.onChange(qsObj, isResetPage);
  };

  useEffect(() => {
    const f = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).filter;
    if (f) {
      setFilter(f);
      applyFilter(f, false);
    } else {
      applyFilter(null, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="row" spacing={5} alignItems="center" sx={{ mb: 1 }}>
      <Box>
        <InputLabel>Has Model</InputLabel>
        <Select
          controlled={false}
          label="Has Model"
          value={filter.hasModel || ""}
          size="small"
          onChange={(ev) => {
            setFilter({ ...filter, hasModel: ev.target.value });
          }}
        >
          <MenuItem value="">all</MenuItem>
          <MenuItem value="true">true</MenuItem>
          <MenuItem value="false">false</MenuItem>
        </Select>
      </Box>
      <Box>
        <InputLabel>Target</InputLabel>
        <Select
          controlled={false}
          label="Target"
          value={filter.target || ""}
          size="small"
          onChange={(ev) => {
            setFilter({ ...filter, target: ev.target.value });
          }}
        >
          <MenuItem value="">all</MenuItem>
          {targetList.map((v) => (
            <MenuItem key={v.key} value={v.key}>
              {v.title}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel>Type</InputLabel>
        <Select
          controlled={false}
          label="Type"
          value={filter.type || ""}
          size="small"
          onChange={(ev) => {
            setFilter({ ...filter, type: ev.target.value });
          }}
        >
          <MenuItem value="">all</MenuItem>
          {typeList.map((v) => (
            <MenuItem key={v.key} value={v.key}>
              {v.title}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel>Address</InputLabel>
        <TextField
          variant="standard"
          value={filter.address || ""}
          onChange={(ev) => setFilter({ ...filter, address: ev.target.value })}
        />
      </Box>
      <ButtonGroup>
        <Button
          variant="outlined"
          onClick={() => {
            applyFilter(null, true);
          }}
        >
          apply
        </Button>
        <Button
          variant="outlined"
          color="success"
          onClick={() => {
            setFilter(defaultFilter);
            applyFilter(defaultFilter, true);
          }}
        >
          reset
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
export default ListFilter;
