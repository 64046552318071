import Box from "@mui/material/Box";

export function LayoutView(props) {
  return (
    <Box
      sx={{
        width: "411px",
        position: "relative",
        minHeight: "823px",
      }}
    >
      {Object.keys(props.dm?.itemList || {}).map((tid, index) => {
        const item = props.dm?.processedItemList[tid];
        const structure = props.dm?.getStructureByRole();
        const zIndex = structure[item.role]?.level || 0;
        if (!item.box) return null;
        return (
          <Box
            key={index}
            sx={{
              zIndex,
              opacity: 0.9 * 0.5 * (zIndex + 1),
              backgroundColor:
                props.dm?.typeList[structure[item.role]?.type]?.color || "#eee",
              position: "absolute",
              left: item.box[0],
              top: item.box[1],
              width: item.box[2] - item.box[0],
              height: item.box[3] - item.box[1],
            }}
          >
            <div
              style="width: 100%; height:100%"
              onClick={() => {
                props.onClick && props.onClick(tid, item);
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
}
