import { Fragment } from "preact";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export function TreeNavigation(props) {
  return (
    <Fragment>
      <IconButton
        color="primary"
        disabled={props.screen?.getParentId(props.id) == null}
        onClick={(e) => {
          const id = props.screen?.getParentId(props.id);
          id && props.onSelect && props.onSelect(id);
          e.preventDefault();
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <IconButton
        color="primary"
        disabled={props.screen?.getChildId(props.id) == null}
        onClick={(e) => {
          const id = props.screen?.getChildId(props.id);
          id && props.onSelect && props.onSelect(id);
          e.preventDefault();
        }}
      >
        <ArrowDownwardIcon />
      </IconButton>
      <IconButton
        color="primary"
        disabled={props.screen?.getSiblingIdLeft(props.id) == null}
        onClick={(e) => {
          const id = props.screen?.getSiblingIdLeft(props.id);
          id && props.onSelect && props.onSelect(id);
          e.preventDefault();
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <IconButton
        color="primary"
        disabled={props.screen?.getSiblingIdRight(props.id) == null}
        onClick={(e) => {
          const id = props.screen?.getSiblingIdRight(props.id);
          id && props.onSelect && props.onSelect(id);
          e.preventDefault();
        }}
      >
        <ArrowForwardIcon />
      </IconButton>
    </Fragment>
  );
}
