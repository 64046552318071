export class Compressor {
  itemList = {};
  structure = [];
  maxTopMargin = 24;
  minHeight = 40;
  viewPortWidth = 411;
  maxElementHeight = 1.618 * (411 - 48);

  constructor(itemList, structure) {
    this.itemList = Object.assign({}, itemList);
    this.structure = [...(structure || [])];
  }

  process() {
    const mainRoleList = [];
    (this.structure || []).forEach((el) => {
      // check if mainBox is required and create it
      const mainBox = this.getItem(this.getItemKeyByRole(el.key));
      const list = this.getListByPrefix(el.key);
      if (!mainBox && list.length) {
        this.itemList[el.key] = {
          role: el.key,
          box: [
            0,
            Math.max(this.getMinTopPosition(list) - this.maxTopMargin, 0),
            this.viewPortWidth,
            this.getMaxBottomPosition(list) + this.maxTopMargin,
          ],
          s: this.getItem(list[0].id).s,
          id: null,
          tag: null,
          params: {},
        };
      }
      this.compressByPrefix(el.key);
      mainRoleList.push(el.key);
    });
    this.compressMainBox(mainRoleList);
  }

  getItemKeyByRole(role) {
    let elKey;
    Object.keys(this.itemList).forEach((key) => {
      if (this.itemList[key].role === role) {
        elKey = key;
      }
    });
    return elKey;
  }

  getItem(key) {
    if (this.itemList[key]) {
      this.itemList[key] = Object.assign({}, this.itemList[key]);
      let el = this.itemList[key];
      if (el.box) {
        el.box = [...el.box];
        return el;
      }
    }
    return null;
  }

  compressMainBox(roleList) {
    // boxes list
    const list = [];
    roleList.forEach((role) => {
      const key = this.getItemKeyByRole(role);
      const item = this.getItem(key);
      if (item) {
        list.push({
          role,
          id: key,
          box: [...item.box],
          diff: 0,
        });
      }
    });
    list.sort((a, b) => (a.box[3] > b.box[3] ? 1 : -1));

    list.forEach((_, i) => {
      if (list[i + 1]) {
        const nextChildList = this.getListByPrefix(list[i + 1].role);
        const minNextTop = Math.min(
          nextChildList.length
            ? this.getMinTopPosition(nextChildList)
            : list[i + 1].box[1],
          list[i + 1].box[1],
        );
        const currentChildList = this.getListByPrefix(list[i].role);
        const maxCurrentBottom = Math.max(
          currentChildList.length
            ? this.getMaxBottomPosition(currentChildList)
            : list[i].box[3],
          list[i].box[3],
        );
        // const diff = list[i + 1].box[1] - list[i].box[3];
        const diff = minNextTop - maxCurrentBottom;
        if (diff - 3 * this.maxTopMargin > 0) {
          for (let j = i + 1; j < list.length; j++) {
            list[j].box[1] -= diff;
            list[j].box[3] -= diff;
            list[j].diff += diff;
          }
        }
      }
    });

    list.forEach((el) => {
      //up all main boxes with its children
      if (el.diff > 0) {
        this.upByPrefix(el.role, el.diff);
      }
    });
  }

  upByPrefix(prefix, diff) {
    Object.keys(this.itemList).forEach((key) => {
      let item = this.getItem(key);
      if (item && item.role?.indexOf(`${prefix}`) === 0) {
        item.box[1] -= diff;
        item.box[3] -= diff;
      }
    });
  }

  getMaxBottomPosition(list) {
    let ret = 0;
    list.forEach((el) => {
      ret = Math.max(ret, el.box[3]);
    });
    return ret;
  }

  getMinTopPosition(list) {
    let ret = list[0]?.box[1];
    list.forEach((el) => {
      ret = Math.min(ret, el.box[1]);
    });
    return ret;
  }

  findIncludedByHeightKey(key, keyList) {
    for (let k in keyList) {
      if (
        key != k &&
        this.itemList[k].box[1] <= this.itemList[key].box[1] &&
        this.itemList[k].box[3] > this.itemList[key].box[3]
      ) {
        return k;
      }
    }
    return null;
  }

  getListByPrefix(prefix) {
    const list = [];
    const filteredItemList = {};
    Object.keys(this.itemList).forEach((key) => {
      let item = this.itemList[key];
      if (item && item.box && item.role?.indexOf(`${prefix}:`) === 0) {
        filteredItemList[key] = key;
      }
    });
    const childList = {};
    const childListParentId = {};
    Object.keys(filteredItemList).forEach((key) => {
      const parentKey = this.findIncludedByHeightKey(key, filteredItemList);
      childListParentId[key] = parentKey;
      if (parentKey) {
        let item = this.itemList[key];
        childList[parentKey] = childList[parentKey] || [];
        childList[parentKey].push({
          id: key,
          box: [...item.box],
        });
      }
    });
    Object.keys(filteredItemList).forEach((key) => {
      const parentKey = childListParentId[key];
      if (!parentKey) {
        let item = this.itemList[key];
        list.push({
          id: key,
          box: [...item.box],
          children: childList[key],
        });
      }
    });
    list.sort((a, b) => (a.box[3] > b.box[3] ? 1 : -1));
    return list;
  }

  compressByPrefix(prefix) {
    // get main box
    const mainBox = this.getItem(this.getItemKeyByRole(prefix));

    // boxes list
    const list = this.getListByPrefix(prefix);

    // cut long elements
    list.forEach((_, i) => {
      const diff = list[i].box[3] - list[i].box[1] - this.maxElementHeight;
      if (diff > 0) {
        const height = Math.max(list[i].box[3] - list[i].box[1], 1);
        list[i].box[3] -= diff;
        // move subbox elements
        const fraction = (height - diff) / height;
        (list[i].children || []).forEach((el) => {
          const h = el.box[3] - el.box[1];
          el.box[1] = list[i].box[1] + fraction * (el.box[1] - list[i].box[1]);
          el.box[3] = el.box[1] + h;
        });
        // move up all bellow boxes
        for (let j = i + 1; j < list.length; j++) {
          list[j].box[1] -= diff;
          list[j].box[3] -= diff;
          // move subbox elements
          (list[j].children || []).forEach((el) => {
            el.box[1] -= diff;
            el.box[3] -= diff;
          });
        }
      }
    });

    // remove huge margins
    list.forEach((_, i) => {
      if (list[i + 1]) {
        const diff = list[i + 1].box[1] - list[i].box[3] - this.maxTopMargin;
        if (diff - 2 * this.maxTopMargin > 0) {
          for (let j = i + 1; j < list.length; j++) {
            list[j].box[1] -= diff;
            list[j].box[3] -= diff;
            // move subbox elements
            (list[j].children || []).forEach((el) => {
              el.box[1] -= diff;
              el.box[3] -= diff;
            });
          }
        }
      }
    });

    list.forEach((el) => {
      const item = this.getItem(el.id);
      item.box = el.box;
      // fix subbox elements
      (el.children || []).forEach((el) => {
        const item = this.getItem(el.id);
        item.box = el.box;
      });
    });

    if (mainBox) {
      if (list.length) {
        // mainBox.box[3] = Math.max(
        //   mainBox.box[3],
        //   this.getMaxBottomPosition(list),
        // );
        // get top marging
        const topMargin = Math.max(
          this.getMinTopPosition(list) - mainBox.box[1],
          0,
        );
        let bottomMargin = Math.max(topMargin, this.maxTopMargin);
        // if (list.length > 1) {
        //  // bottomMargin = -1;
        //  // bottomMargin = Math.max(
        //  //  list[list.length - 1].box[1] -
        //  //    list[list.length - 2].box[3],
        //  //  0
        //  // );
        //  // bottomMargin = Math.min(
        //  //  mainBox.box[3] - list[list.length - 1].box[3],
        //  //  this.maxElementHeight / 2
        //  // );
        // } else if (list.length === 1) {
        //  //bottomMargin = 0;
        // }
        if (bottomMargin >= 0) {
          mainBox.box[3] = Math.min(
            mainBox.box[3],
            this.getMaxBottomPosition(list) + bottomMargin,
          );
        }
      } else if (mainBox.box[3] - mainBox.box[1] > this.maxElementHeight) {
        mainBox.box[3] = mainBox.box[1] + this.maxElementHeight;
      }
    }
  }
}
