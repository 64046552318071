import Helmet from "preact-helmet";

import { useEffect } from "preact/hooks";

export const GoogleAuth = (props) => {
  useEffect(() => {
    !window.handleCredentialResponse &&
      (window.handleCredentialResponse = (data) => {
        props.onSuccess && props.onSuccess(data?.credential);
      });
    return () => {
      window.handleCredentialResponse && delete window.handleCredentialResponse;
    };
  }, [props]);

  return (
    <>
      <Helmet
        script={[
          {
            src: "//accounts.google.com/gsi/client",
            type: "text/javascript",
          },
        ]}
        meta={[
          {
            name: "policy",
            content: "strict-origin-when-cross-origin",
          },
        ]}
      />
      <div
        id="g_id_onload"
        data-callback="handleCredentialResponse"
        data-context="signin"
        data-client_id={props.clientId}
        data-auto_prompt="false"
      />
      <div
        class="g_id_signin"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="rectangular"
        data-logo_alignment="left"
        data-auto_select="true"
        data-ux_mode="redirect"
      />
    </>
  );
};
