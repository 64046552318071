export const LocationOption = [
  {
    k: "WW",
    t: "Worldwide",
  }, {
    k: "EUR",
    t: "Europe",
  }, {
    k: "ME",
    t: "Middle East",
  }, {
    k: "AFR",
    t: "Africa",
  }, {
    k: "NA",
    t: "North America",
  }, {
    k: "LATAM",
    t: "Latin America",
  }, {
    k: "APAC",
    t: "Asia-Pacific",
  }
];

export const AudienceOption = [
  {
    k: "ANY",
    t: "Any",
  }, {
    k: "AD",
    t: "Adults",
  }, {
    k: "ADM",
    t: "Adults / Men",
  }, {
    k: "ADW",
    t: "Adults / Women",
  }, {
    k: "CH",
    t: "Children / Teens",
  }, {
    k: "CHB",
    t: "Children / Teens / Boys",
  }, {
    k: "CHG",
    t: "Children / Teens / Girls",
  }, {
    k: "OMW",
    t: "Old Men & Women",
  }
];

export const ProductCategoryOption = [
  {
    k: "fashion",
    t: "Fashion and Apparel",
  }, {
    k: "home",
    t: "Home and Garden",
  }, {
    k: "electronics",
    t: "Electronics and Gadgets",
  }, {
    k: "beauty",
    t: "Beauty and Cosmetics",
  }, {
    k: "health",
    t: "Health and Pharmacy",
  }, {
    k: "sport",
    t: "Sports and Fitness",
  }, {
    k: "baby",
    t: "Baby and Kids",
  }, {
    k: "jewelry",
    t: "Jewelry and Accessories",
  }, {
    k: "art",
    t: "Art and Crafts",
  }, {
    k: "food",
    t: "Food and Beverages",
  }, {
    k: "book",
    t: "Books and Stationery",
  }, {
    k: "pet",
    t: "Pet Supplies",
  }, {
    k: "auto",
    t: "Auto, Parts and Accessories",
  }, {
    k: "outdoor",
    t: "Outdoor and Adventure",
  }, {
    k: "decor",
    t: "Home Decor and Furnishings",
  }, {
    k: "other",
    t: "Others",
  }
];

export const ColorSchemaOption = [
  {
    k: "",
    t: "Unknown",
  }, {
    k: "bw",
    t: "Black & White",
  }, {
    k: "colorful",
    t: "Colorful",
  }
];

export const ColorThemeOption = [
  {
    k: "",
    t: "Unknown",
  }, {
    k: "light",
    t: "Light",
  }, {
    k: "dark",
    t: "Dark",
  }
];

export const ColorStyleOption = [
  {
    k: "",
    t: "Unknown",
  }, {
    k: "transparent",
    t: "Transparent",
  }, {
    k: "highlighted",
    t: "Highlighted",
  }
];

export const ContrastLevelOption = [
  {
    k: "",
    t: "Unknown",
  }, {
    k: "AA",
    t: "AA",
  }, {
    k: "AAA",
    t: "AAA",
  }
];

export const FontSchemeOption = [
  {
    k: "",
    t: "Unknown",
  }, {
    k: "single",
    t: "Single Font",
  }, {
    k: "multiple",
    t: "Multiple Fonts",
  }
];

export const AlignmentSchemeOption = [
  {
    k: "",
    t: "Unknown",
  }, {
    k: "left",
    t: "Left",
  }, {
    k: "center",
    t: "Center",
  }, {
    k: "right",
    t: "Right",
  }
];

