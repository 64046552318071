import ServiceContext from "../../components/providers/service";
import { useContext, useEffect, useState, useRef } from "preact/compat";
import {
  Stack,
  ButtonGroup,
  Box,
  Typography,
  CircularProgress,
  Link,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LayoutPreview } from "./components/layoutPreview";
import { LayoutPreviewParams } from "./components/layoutPreviewParams";
import { viewList } from "../page/constant";

const pageSize = { w: 411, h: 823 };

const PreviewGenerate = (props) => {
  const { api } = useContext(ServiceContext);

  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [basedOnId, setBasedOnId] = useState(null);
  //const [params, setParams] = useState(null);
  const [view, setView] = useState(viewList[0]['key'] || 'design');
  const paramsRef = useRef({});

  useEffect(() => {
    if (!props.id) {
      setData(null);
      paramsRef?.current && delete paramsRef.current.params;
    }
  }, [props.id]);

  useEffect(() => {
    if (api && api.state == "connected") {
      setIsConnected(true);
    }
  }, [api, api?.state]);

  useEffect(() => {
    if (props.id) {
      if (api && api.state === "connected") {
        setIsLoading(true);
        api.action(
          "previewGenerate",
          {
            id: props.id,
          },
          (data) => {
            if (data?.ok) {
              setData(data?.data || []);
            }
            setIsLoading(false);
          }
        );
      }
    } else {
      setData(null);
      setBasedOnId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, api?.state, props.id]);

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        {!props.id && (
          <Typography variant="h4">AI Generator Preview</Typography>
        )}
        {!props.id && basedOnId && (
          <Typography color="text.primary">
            based on:{" "}
            <Link href={`/preview/show/${basedOnId}`}>{basedOnId}</Link>
          </Typography>
        )}
        {props.id && (
          <>
            <Typography variant="h4">Dataset Model Preview</Typography>
            <Typography color="text.primary">
              [<Link href={`/page/${props?.id}`}>back to model</Link>]
            </Typography>
          </>
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            width: `${pageSize.w}px`,
            minHeight: `${pageSize.h}px`,
            overflowX: "hidden",
            position: "relative",
            border: "1px solid black",
          }}
        >
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 1000,
                left: pageSize.w / 2 - 35,
                top: pageSize.h / 2 - 35,
              }}
            >
              <CircularProgress size={70} thickness={6} />
            </Box>
          )}
          {!isLoading && data && (
            <LayoutPreview
              view={view}
              pageSize={pageSize}
              box={data?.boxes || []}
              boxParam={data?.box_params || []}
              boxType={data?.boxes_type || []}
              boxRelation={data?.box_rel || []}
            />
          )}
        </Box>
        <Stack direction="column" spacing={2}>
          <ButtonGroup variant="outlined" sx={{ ml: 2 }}>
            <LoadingButton
              variant="outlined"
              disabled={!isConnected || isLoading}
              loading={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                setIsLoading(true);
                setBasedOnId(null);
                api.action(
                  "previewGenerate",
                  {
                    id: props.id,
                    input: paramsRef?.current?.params || null,
                  },
                  (data) => {
                    if (data?.ok) {
                      setData(data.data || []);
                      setBasedOnId(data?.id || null);
                    }
                    setIsLoading(false);
                  }
                );
              }}
            >
              generate
            </LoadingButton>
          </ButtonGroup>
            <Stack
              direction="column"
              spacing={0}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <FormControl sx={{ m: 2, minWidth: 300 }}>
                <InputLabel shrink htmlFor="select-view">
                  View
                </InputLabel>
                <Select
                  native
                  defaultValue={view}
                  onChange={(e) => {
                    setView(e.target.value);
                  }}
                  label="View"
                  inputProps={{
                    id: "select-view",
                  }}
                >
                  {viewList.map((el) => {
                    return (
                      <option key={el.key} value={el.key}>
                        {el.title}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          {false && !props.id && (
            <LayoutPreviewParams
              type={"pdp"}
              onUpdate={(params) => {
                paramsRef.current.params = params;
              }}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default PreviewGenerate;
