import { Router, route } from "preact-router";

import Header from "./header";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";

import ServiceContext from "./providers/service";
import ConnectionContext from "./providers/connection";

import Home from "../routes/home";
import PageList from "../routes/page/list";
import PageDetails from "../routes/page/details";
import SessionList from "../routes/session/list";
import SessionDetails from "../routes/session/details";
import PreviewGenerate from "../routes/preview/generate";

import ApiClientFactory from "../services/api";
import { useState, useEffect } from "preact/hooks";
import { GoogleAuth } from "./auth/google";

import { useRecoilState } from "recoil";
import tokenStateAtom from "../atoms/token";
import Logger from "js-logger";

const App = () => {
  const [token, setToken] = useRecoilState(tokenStateAtom);
  const [,] = useState(false);
  const [api, setApi] = useState(null);
  const [isConnected, setIsConected] = useState(false);

  //useEffect(() => {
  //  console.log("token", token);
  //}, [token]);

  useEffect(() => {
    setApi(
      ApiClientFactory(
        Logger.get("api"),
        {
          url: process.env.PREACT_APP_API_URL,
        },
        () => {
          setIsConected(true);
        },
        () => {
          setIsConected(false);
        },
        () => {
          setToken(null);
        }
      )
    );
  }, [setToken]);

  useEffect(() => {
    if (api && token) {
      // revalidate token
      api.options.token = token;
      api
        ?.fetch("/v1/auth/refresh", {}, "POST")
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            const token = res.token;
            setToken(token);
            api.options.token = res.token;
            api?.connect();
          } else {
            setToken(null);
          }
        })
        .catch(() => {
          setToken(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <ServiceContext.Provider
      value={{
        api,
      }}
    >
      <ConnectionContext.Provider value={isConnected}>
        <Box sx={{ display: "flex" }}>
          {token && <CssBaseline />}
          {token && (
            <Header
              onLogout={() => {
                api?.disconnect();
                setToken(null);
                route("/");
              }}
            />
          )}
          <Box component="main" sx={{ flexGrow: 1, p: 4, pt: 9 }}>
            {token && (
              <Router>
                <Home path="/" />
                <PageList path="/pages" page={1} />
                <PageList path="/pages/:page" />
                <PageDetails path="/page/:id" />
                <SessionList path="/sessions" page={1} />
                <SessionList path="/sessions/:page" />
                <SessionDetails path="/session/:id" />
                <PreviewGenerate path="/preview/show/:id" />
                <PreviewGenerate path="/preview/generate" />
              </Router>
            )}
            {!token && (
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "80vh" }}
              >
                <Grid item xs={3}>
                  <Box
                    sx={{
                      borderRadius: "5px",
                      border: "1px solid black",
                      minWidth: "240px",
                      minHeight: "100px",
                      p: 5,
                    }}
                  >
                    <GoogleAuth
                      clientId={process.env.PREACT_APP_AUTH_GOOGLE_CLIENT_ID}
                      onSuccess={(token) => {
                        // pass token to server
                        api
                          ?.fetch("/v1/auth", { token }, "POST")
                          .then((res) => res.json())
                          .then((res) => {
                            if (res.success) {
                              setToken(res.token);
                              api.options.token = res.token;
                              api?.connect();
                            }
                          })
                          .catch(() => {});
                      }}
                      onError={() => {}}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </ConnectionContext.Provider>
    </ServiceContext.Provider>
  );
};

export default App;
