let client = null;

export default (logger, options, onConnected, onDisconnected, onAuthFailed) => {
  if (!client && global.ActionheroWebsocketClient) {
    global.ActionheroWebsocketClient.prototype.fetch = function (
      uri,
      params,
      method
    ) {
      const url = new URL(this.options.url + this.options.apiPath + uri);
      const options = {};
      method = (method || "POST").toUpperCase();
      params = params || {};

      switch (method) {
        case "GET":
          for (let k in params) {
            url.searchParams.append(k, params[k]);
          }
          break;
        case "POST":
        default:
          options.method = "POST";
          options.headers = {
            "Content-Type": "application/json;charset=utf-8",
          };
          options.body = JSON.stringify(params);
          break;
      }

      if (this.options.token) {
        options.headers = options.headers || {};
        options.headers["Authorization"] = `Bearer ${this.options.token}`;
      }

      return fetch(url, options);
    };

    global.ActionheroWebsocketClient.prototype.urlWithSession = function () {
      let self = this;
      let url = self.options.url;
      if (self.options.cookieKey && self.options.cookieKey.length > 0) {
        let cookieValue = self.getCookie(self.options.cookieKey);
        if (cookieValue && cookieValue.length > 0) {
          url += `?${self.options.cookieKey}=${cookieValue}`;
        }
      }
      if (self.options.token) {
        url += `${url.indexOf("?") !== -1 ? "&" : "?"}token=${
          self.options.token
        }`;
      }
      return url;
    };

    client = new global.ActionheroWebsocketClient(options);

    client.on("connected", () => {
      onConnected && onConnected();
      logger.debug("connected");
    });
    client.on("disconnected", () => {
      onDisconnected && onDisconnected();
      logger.debug("disconnected :(");
    });

    client.on("error", (error) => {
      logger.error(error.stack);
    });
    client.on("reconnect", () => {
      onConnected && onConnected();
      logger.debug("reconnect");
    });
    client.on("reconnecting", () => {
      logger.debug("reconnecting");
    });

    client.on("alert", (message) => {
      logger.warn("alert", message);
    });
    client.on("api", (message) => {
      logger.debug("api", message);
    });

    // client.on("welcome", (message) => {
    //  logger.debug("welcome", message);
    // });
    client.on("say", (message) => {
      logger.debug("chat:say", message);
    });

    client.on("message", (message) => {
      if (typeof message === "string") {
        switch (message) {
          case "app:auth:forbidden":
            onAuthFailed && onAuthFailed();
            break;
        }
      }
    });

    // client.connect((err) => {
    //  if (err) {
    //    logger.error(err.stack);
    //  }
    // });
  }
  return client;
};
