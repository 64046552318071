import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "preact/hooks";

export function ListActionList(props) {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <>
      <ButtonGroup variant="outlined" size="small">
        <LoadingButton
          variant="outlined"
          color="error"
          loading={isDeleting}
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleting(true);
            props.onDelete &&
              props.onDelete(() => {
                setIsDeleting(false);
              });
          }}
        >
          delete
        </LoadingButton>
      </ButtonGroup>
    </>
  );
}
