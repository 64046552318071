import ServiceContext from "../../components/providers/service";
import { useContext, useEffect, useState, useRef } from "preact/compat";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";

import { PageDomManager } from "../../models/pageDomManager";
import { ScreenDomManager } from "../../models/screenDomManager";
import { DataManagerFactory } from "../../models/data/factory";
import { ImagePalette } from "../../models/imagePalette";

import { TreeBreadcrumb } from "./components/treeBreadcrumb";
import { TreeNavigation } from "./components/treeNavigation";
import { ElementRoleForm } from "./components/elementRoleForm";
import { LayoutTreeView } from "./components/layoutTreeView";
import { LayoutView } from "./components/layoutView";
import { PaletteView } from "./components/paletteView";
import { ElementColorView } from "./components/elementColorView";
import { DetailsActionList } from "./components/detailsActionList";
import { ElementParamList } from "./components/elementParamList";
import { CommonParamList } from "./components/commonParamList";

import { useRecoilState, useResetRecoilState, } from "recoil";
import pdItemListStateAtom from "../../atoms/pageDataItemList";
import pdWidgetStatusStateAtom from "../../atoms/pdWidgetStatus";
import styles from "./details.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const pageSize = { w: 411, h: 823, s: 600 };

const getPaletteList = (p) => {
  const paletteList = [];
  if (p) {
    p.forEach((rgb) => {
      paletteList.push(`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`);
    });
  }
  return paletteList;
};

const truncate = (str, length, separator = "...") => {
  if (!str || str.length <= length) {
    return str;
  }
  let pad = Math.round(length - separator.length) / 2;
  return [str.substr(0, pad), separator, str.substr(str.length - pad)].join("");
};

const PageDetails = (props) => {
  const { api } = useContext(ServiceContext);

  const [page, setPage] = useState(null);
  const [screen, setScreen] = useState(null);
  const [currentScreenLoading, setCurrentScreenLoading] = useState(null);
  const [dataManager, setDataManager] = useState(null);
  const [currentScreen, setCurrentScreen] = useState(0);
  //const [screenData, setScreenData] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedNodePalette, setSelectedNodePalette] = useState(null);
  //const [selectedNode, setSelectedNode] = useState(null);
  const [itemList, setItemList] = useRecoilState(pdItemListStateAtom);
  const resetItemList = useResetRecoilState(pdItemListStateAtom);

  const [, setIsLoading] = useState(false);

  const [dataUUID, setDataUUID] = useState(null);

  const canvasRef = useRef();
  const selectedElementRef = useRef();

  const [widgetStatus, setWidgetStatus] = useRecoilState(pdWidgetStatusStateAtom);

  // accordion state
  // const [isLayoutTreeOpen, setIsLayoutTreeOpen] = useState()

  const preloadScreen = (api, id, i, page) => {
    if (
      api &&
      api.state === "connected" &&
      page.screenMeta[i] &&
      !page?.getScreen(i)
    ) {
      api.action(
        "pageScreenDetails",
        {
          id,
          screen: i,
        },
        (data) => {
          const meta = page.screenMeta[i] || {};
          const topOffsetDelta =
            meta?.height - i * pageSize.s - meta?.screen_height;
          page.setScreen(
            i,
            new ScreenDomManager(data.dom, data.screenUrl, {
              width: meta.width || 0,
              height: meta.height || 0,
              screenHeight: meta.screen_height || 0,
              screenWidth: meta.screen_width || 0,
              screenTopOffset: topOffsetDelta < 0 ? topOffsetDelta : 0,
            })
          );
        }
      );
    }
  };

  // load page data
  useEffect(() => {
    if (api && api.state === "connected") {
      setIsLoading(true);
      api.action("pageDetails", { id: props.id }, (data) => {
        //logger.debug("pageDetails", data);
        const page = new PageDomManager(
          data.id,
          data.target,
          data.type,
          data.address,
          data.meta?.pages || []
        );
        setPage(page);
        let dataManager = DataManagerFactory.get(data.type);
        // set saved itemList
        dataManager.setInitItemList(data.itemList || {});
        dataManager.setInitMeta(data.paramList || {});
        // load unsaved from cache
        if (
          itemList &&
          itemList.id === props.id &&
          itemList.time &&
          itemList.time + 24 * 3600 * 1000 > new Date().getTime()
        ) {
          dataManager.setMeta(itemList.meta || {});
          dataManager.setItemList(itemList.list || {});
        } else {
          resetItemList();
        }
        setDataManager(dataManager);
        setDataUUID(dataManager.uuid);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, api?.state, props]);

  // load page screen data
  useEffect(() => {
    const screen = page?.getScreen(currentScreen);
    if (screen) {
      setScreen(screen);
      preloadScreen(api, props.id, currentScreen + 1, page);
      return;
    }

    if (api && api.state === "connected" && page) {
      currentScreen !== currentScreenLoading &&
        setCurrentScreenLoading(currentScreen);

      const _currentScreenLoading = currentScreen;

      api.action(
        "pageScreenDetails",
        {
          id: props.id,
          screen: currentScreen,
        },
        (data) => {
          //logger.debug("pageScreenDetails", data);

          const meta = page.screenMeta[currentScreen] || {};
          const topOffsetDelta =
            meta?.height - currentScreen * pageSize.s - meta?.screen_height;
          page.setScreen(
            currentScreen,
            new ScreenDomManager(data.dom, data.screenUrl, {
              width: meta.width || 0,
              height: meta.height || 0,
              screenHeight: meta.screen_height || 0,
              screenWidth: meta.screen_width || 0,
              screenTopOffset: topOffsetDelta < 0 ? topOffsetDelta : 0,
            })
          );

          setScreen(page.getScreen(currentScreen));

          currentScreen === _currentScreenLoading &&
            setCurrentScreenLoading(null);

          preloadScreen(api, props.id, currentScreen + 1, page);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, api?.state, props.id, page, currentScreen]);

  // draw screen image
  useEffect(() => {
    //const screen = page?.getScreen(currentScreen);
    if (screen) {
      const { width, height } = canvasRef?.current
        ? canvasRef.current.getBoundingClientRect()
        : {};
      const screenImage = new Image();
      screenImage.crossOrigin = "";
      screenImage.addEventListener("load", () => {
        canvasRef.current
          ?.getContext("2d")
          .drawImage(screenImage, 0, 0, width, height);
        if (!screen.imgData) {
          screen.setImageData(canvasRef.current.toDataURL("image/png"));
        }
      });
      screenImage.src = screen.getImageUrl();
    }
  }, [page, screen]);

  useEffect(() => {
    if (selectedElementRef) {
      if (selectedNodeId && screen) {
        const rectPosition = screen.getRectPositionByTreeId(selectedNodeId);
        if (rectPosition) {
          const style = selectedElementRef.current.style;
          style.left = `${rectPosition[0]}px`;
          style.top = `${rectPosition[1]}px`;
          style.width = `${rectPosition[2] - rectPosition[0]}px`;
          style.height = `${rectPosition[3] - rectPosition[1]}px`;
          style.display = "block";
          return;
        }
      }
      selectedElementRef.current.style.display = "none";
    }
  }, [selectedElementRef, selectedNodeId, screen]);

  useEffect(() => {
    if (selectedNodeId && screen) {
      const rectPosition = screen.getRectPositionByTreeId(selectedNodeId);
      if (rectPosition) {
        const imgData = canvasRef.current
          ?.getContext("2d")
          .getImageData(
            rectPosition[0],
            rectPosition[1],
            rectPosition[2] - rectPosition[0],
            rectPosition[3] - rectPosition[1]
          );
        const ip = new ImagePalette(imgData, { colorCount: 5 });
        setSelectedNodePalette(ip.getPalette());
      }
    }
  }, [selectedNodeId, screen]);

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4">Page Details</Typography>
        {dataManager && (
          <>
            <Breadcrumbs aria-label="breadcrumb">
              <span>{page?.target || "unknown"}</span>
              <span>{page?.type || "-"}</span>
              <Typography color="text.primary">
                <Link href={page?.address}>{truncate(page?.address, 60)}</Link>{" "}
                [<Link href={`/preview/show/${page?.id}`}>preview</Link>]
              </Typography>
            </Breadcrumbs>
            <DetailsActionList
              dm={dataManager}
              dmKey={dataUUID}
              onSave={(cb) => {
                dataManager?.fixParams && dataManager.fixParams();
                api.action(
                  "pageItemDataSave",
                  {
                    id: props.id,
                    paramList: dataManager.meta || {},
                    itemList: dataManager.itemList,
                    processedItemList: dataManager.processedItemList,
                  },
                  (res) => {
                    if (res.success) {
                      resetItemList();
                      dataManager.setInitItemList(dataManager.itemList);
                      dataManager.setInitMeta(dataManager.meta);
                      setDataUUID(dataManager.uuid);
                    }
                    cb && cb();
                  }
                );
              }}
              onReset={(cb) => {
                resetItemList();
                dataManager.setInitItemList(dataManager.initItemList);
                dataManager.setInitMeta(dataManager.initMeta);
                setDataUUID(dataManager.uuid);
                cb && cb();
              }}
              onDelete={(cb) => {
                api.action(
                  "pageItemDataSave",
                  { id: props.id, itemList: null, meta: {} },
                  (res) => {
                    if (res.success) {
                      resetItemList();
                      dataManager.setInitItemList({});
                      dataManager.setInitMeta({});
                      setDataUUID(dataManager.uuid);
                    }
                    cb && cb();
                  }
                );
              }}
            />
          </>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item>
          <Stack
            sx={{ height: pageSize.h + 2 }}
            spacing={1}
            direction="row"
            justifyContent="space-between"
          >
            <Box
              sx={{
                border: "1px solid black",
                position: "relative",
                overflow: "hidden",
              }}
            >
              {currentScreenLoading !== null && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 1000,
                    left: pageSize.w / 2 - 35,
                    top: pageSize.h / 2 - 35,
                  }}
                >
                  <CircularProgress size={70} thickness={6} />
                </Box>
              )}
              <div
                ref={selectedElementRef}
                style="position:absolute;border:1px solid red; background: rgba(25, 118, 210, 0.2); pointer-events:none;"
              />
              <Box
                sx={{
                  filter:
                    currentScreenLoading !== null ? "grayscale(100%)" : "",
                }}
              >
                <canvas
                  ref={canvasRef}
                  width={pageSize.w}
                  height={pageSize.h}
                  style="background:#eee"
                  tabindex="1"
                  onClick={(e) => {
                    const rect = e.target.getBoundingClientRect();
                    const x = Math.round(e.clientX - rect.x);
                    const y = Math.round(e.clientY - rect.y);
                    const selectedId = screen?.findIdByCoordinates(x, y);
                    setSelectedNodeId(screen?.getTreeIdById(selectedId));
                  }}
                  onKeyDown={(e) => {
                    switch (e.code) {
                      case "PageDown":
                        if (page.screenMeta[currentScreen + 1]) {
                          setCurrentScreen(currentScreen + 1);
                        }
                        break;
                      case "PageUp":
                        if (page.screenMeta[currentScreen - 1]) {
                          setCurrentScreen(currentScreen - 1);
                        }
                        break;
                    }
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </Box>
            </Box>
            {page?.getScreenCnt() > 1 && (
              <Slider
                aria-label="pager"
                orientation="vertical"
                value={-1 * currentScreen}
                track="inverted"
                marks={true}
                min={-1 * ((page?.getScreenCnt() || 1) - 1)}
                max={0}
                step={1}
                onChange={(_, v) => {
                  setCurrentScreen(-1 * v);
                }}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Accordion defaultExpanded={!!widgetStatus.mt}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => {
                    setWidgetStatus(v => Object.assign({}, v, { mt: !v.mt }))
                  }}
                >
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <AssignmentOutlinedIcon />
                    <Typography variant="h5">
                      Params
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {dataManager && (
                    <CommonParamList
                      meta={dataManager.meta}
                      uuid={dataManager.uuid}
                      onChange={(k, v) => {
                        dataManager.setMetaByKey(k, v)
                        setItemList({
                          id: props.id,
                          time: new Date().getTime(),
                          meta: Object.assign({}, dataManager.meta || {}),
                          list: Object.assign({}, dataManager.itemList),
                        });
                        setDataUUID(dataManager.uuid);
                      }}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
              {selectedNodeId && (<Accordion defaultExpanded={!!widgetStatus.el}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => {
                    setWidgetStatus(v => Object.assign({}, v, { el: !v.el }))
                  }}
                >


                  <Stack direction="row" spacing={1} alignItems="center">
                    <HighlightAltOutlinedIcon />
                    <Typography variant="h5">
                      Selected Element{" "}
                      {selectedNodeId && (
                        <span>
                          &lt;
                          {screen?.getNodeByTreeId(selectedNodeId)?.name}
                          &gt;
                        </span>
                      )}
                      {!selectedNodeId && <span>&lt;?&gt;</span>}
                    </Typography>
                    {false && <PaletteView list={selectedNodePalette} />}
                    {selectedNodeId && (
                      <ElementColorView
                        params={
                          screen?.getNodeByTreeId(selectedNodeId)?.computedStyles ||
                          {}
                        }
                      />
                    )}
                    {selectedNodeId && (
                      <ElementParamList
                        params={
                          screen?.getNodeByTreeId(selectedNodeId)?.computedStyles ||
                          {}
                        }
                      />
                    )}
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {selectedNodeId && (
                    <Box>
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{ margin: 2 }}
                      />

                      <Typography gutterBottom variant="h6">
                        Navigation:
                      </Typography>

                      <Box>
                        <TreeNavigation
                          id={screen?.getIdByTreeId(selectedNodeId)}
                          screen={screen}
                          onSelect={(id) => {
                            setSelectedNodeId(screen?.getTreeIdById(id));
                          }}
                        />
                      </Box>

                      <Box>
                        <TreeBreadcrumb
                          id={screen?.getIdByTreeId(selectedNodeId)}
                          screen={screen}
                          onSelect={(id) => {
                            setSelectedNodeId(screen?.getTreeIdById(id));
                          }}
                        />
                      </Box>

                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{ margin: 2 }}
                      />

                      <Typography gutterBottom variant="h6">
                        Role:
                      </Typography>
                      <ElementRoleForm
                        tid={selectedNodeId}
                        sameSiblingTreeIdList={screen?.getSameSiblingTreeIdList(
                          selectedNodeId
                        )}
                        roleList={dataManager?.structure}
                        role={dataManager?.getItem(selectedNodeId)?.role || ""}
                        onSave={(tidList, role) => {
                          (tidList || []).forEach((tid) => {
                            // add item to list
                            const node = screen?.getNodeByTreeId(tid);
                            const box = screen?.getRectPosition(
                              node.id,
                              currentScreen * pageSize.s
                            );
                            let item = {
                              role,
                              box,
                              s: currentScreen,
                              id: node.id,
                              tag: node.name,
                              params: Object.assign(
                                {
                                  //TODO: fix for siblings??
                                  palette: getPaletteList(selectedNodePalette),
                                },
                                node.computedStyles || {}
                              ),
                            };
                            dataManager?.setItem(tid, item);
                          });
                          setItemList({
                            id: props.id,
                            time: new Date().getTime(),
                            meta: Object.assign({}, dataManager.meta || {}),
                            list: Object.assign({}, dataManager.itemList),
                          });
                          setDataUUID(dataManager.uuid);
                        }}
                        onDelete={(tidList) => {
                          (tidList || []).forEach((tid) => {
                            dataManager?.removeItem(tid);
                          });
                          setDataUUID(dataManager.uuid);
                        }}
                      />
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
              )}
              <Accordion defaultExpanded={!!widgetStatus.lt}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => {
                    setWidgetStatus(v => Object.assign({}, v, { lt: !v.lt }))
                  }}
                >
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <AccountTreeOutlinedIcon />
                    <Typography variant="h5">
                      Layout Tree
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {dataManager && (
                    <>
                      <LayoutTreeView
                        dm={dataManager}
                        dmKey={dataUUID}
                        onSelectItem={(tid, item) => {
                          setCurrentScreen(item.s);
                          setSelectedNodeId(tid);
                        }}
                      />
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box
            sx={{
              border: "1px solid black",
              overflowX: "hidden",
              height: `${pageSize.h + 2}px`,
              width: `${pageSize.w + 2}px`,
            }}
            className={styles.layoutView}
          >
            <LayoutView
              dm={dataManager}
              dmKey={dataUUID}
              onClick={(tid, item) => {
                setCurrentScreen(item.s || 0);
                setSelectedNodeId(tid);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageDetails;
