import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import LockClockIcon from "@mui/icons-material/LockClock";
import {
  useRef,
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
} from "preact/compat";
import ServiceContext from "../../components/providers/service";
import { GrabForm } from "./components/grabForm";
import { DetailsActionList } from "./components/detailsActionList";
import { GrabQueue } from "./components/grabQueue";
import { route } from "preact-router";

const pageSize = { w: 411, h: 823, s: 600 };

const SessionDetails = (props) => {
  const canvasRef = useRef();
  const { api } = useContext(ServiceContext);
  const [isLoading, setIsLoading] = useState(true);
  const [chatRoomId, setChatRoomId] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [target, setTarget] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [queueList, setQueueList] = useState([]);
  const [destroyOnEnd, setDestroyOnEnd] = useState(true);

  // load session data
  useEffect(() => {
    if (api && api.state === "connected") {
      setIsLoading(true);
      api.action("sessionDetails", { id: props.id }, (data) => {
        if (data.error) {
          setIsDeleted(true);
          setIsLoading(false);
        } else {
          const chatId = data.chatId;
          if (chatId) {
            api.roomAdd(chatId, (data) => {
              if (data.data) {
                setChatRoomId(chatId);
              }
              setIsLoading(false);
            });
          }
          setTarget(data?.target);
        }
      });
    }
  }, [api, api?.state, props.id]);

  // process messages
  useEffect(() => {
    if (api && chatRoomId) {
      const chatMessageHandler = (message) => {
        if (chatRoomId !== message.room) return;
        const data = message.message;
        switch (data?.type) {
          case "screencast":
            // eslint-disable-next-line no-case-declarations
            const image = new Image(pageSize.w, pageSize.h);
            image.onload = function () {
              canvasRef.current
                ?.getContext("2d")
                .drawImage(image, 0, 0, pageSize.w, pageSize.h);
            };
            image.src = `data:image/jpeg;base64,${data?.data}`;
            break;
          case "page:info":
            setUrl(data?.url);
            setTitle(data?.title);
            break;
          case "page:status":
            setIsLocked(data?.isLocked);
            break;
          case "destroyed":
            setIsDeleted(true);
            break;
          case "page:grab:queue":
            setQueueList(data?.queue || []);
            break;
        }
      };
      api.on("say", chatMessageHandler);
      return () => {
        api.off("say", chatMessageHandler);
        api.roomLeave(chatRoomId, () => {
          setChatRoomId(null);
        });
      };
    }
  }, [api, chatRoomId]);

  // add event handlers
  useLayoutEffect(() => {
    if (api && api.state === "connected" && chatRoomId && canvasRef.current) {
      const mousedownHandler = (e) => {
        api.say(
          chatRoomId,
          JSON.stringify({
            type: "mousedown",
            params: {
              x: parseInt((pageSize.w * e.layerX) / screen.offsetWidth, 10),
              y: parseInt((pageSize.h * e.layerY) / screen.offsetHeight, 10),
            },
          })
        );
      };
      const mouseupHandler = (e) => {
        api.say(
          chatRoomId,
          JSON.stringify({
            type: "mouseup",
            params: {
              x: parseInt((pageSize.w * e.layerX) / screen.offsetWidth, 10),
              y: parseInt((pageSize.h * e.layerY) / screen.offsetHeight, 10),
            },
          })
        );
      };
      const wheelHandler = (e) => {
        api.say(
          chatRoomId,
          JSON.stringify({
            type: "wheel",
            params: {
              x: parseInt((pageSize.w * e.layerX) / screen.offsetWidth, 10),
              y: parseInt((pageSize.h * e.layerY) / screen.offsetHeight, 10),
              deltaX: parseInt(
                (pageSize.w * e.wheelDeltaX) / screen.offsetWidth,
                10
              ),
              deltaY: parseInt(
                (pageSize.h * e.wheelDeltaY) / screen.offsetHeight,
                10
              ),
            },
          })
        );
      };
      const keyupHandler = (e) => {
        api.say(
          chatRoomId,
          JSON.stringify({
            type: "keyup",
            params: {
              code: e.code,
              key: e.key,
              which: e.which,
            },
          })
        );
      };

      const screen = canvasRef.current;
      screen.addEventListener("mousedown", mousedownHandler);
      screen.addEventListener("mouseup", mouseupHandler);
      screen.addEventListener("wheel", wheelHandler);
      screen.addEventListener("keyup", keyupHandler);

      return () => {
        screen.removeEventListener("mousedown", mousedownHandler);
        screen.removeEventListener("mouseup", mouseupHandler);
        screen.removeEventListener("wheel", wheelHandler);
        screen.removeEventListener("keyup", keyupHandler);
      };
    }
  }, [api, chatRoomId, canvasRef]);

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h4">Grabber Session Details</Typography>
          {isLocked && <LockClockIcon fontSize="large" color="error" />}
        </Stack>
        {!(isDeleted || isLoading) && (
          <DetailsActionList
            onDelete={(cb) => {
              api.action("sessionDelete", { id: props.id }, (res) => {
                if (res.ok) {
                  route("/sessions");
                }
                cb && cb();
              });
            }}
          />
        )}
      </Stack>
      {(isDeleted || isLoading) && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "80vh" }}
        >
          <Grid item xs={3}>
            {isDeleted && (
              <Box>
                Session can't be found or has been deleted. Go to{" "}
                <Link
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    route("/sessions");
                  }}
                >
                  session list
                </Link>
                .
              </Box>
            )}
            {isLoading && <CircularProgress />}
          </Grid>
        </Grid>
      )}
      {!(isDeleted || isLoading) && (
        <Grid container spacing={2}>
          <Grid item>
            <Stack
              sx={{ height: pageSize.h + 2 }}
              spacing={1}
              direction="row"
              justifyContent="space-between"
            >
              <Box
                sx={{
                  border: "1px solid black",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                {isLocked && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 1000,
                      left: pageSize.w / 2 - 35,
                      top: pageSize.h / 2 - 35,
                    }}
                  >
                    <CircularProgress size={70} thickness={6} />
                  </Box>
                )}
                <Box
                  sx={{
                    filter: isLocked ? "grayscale(100%)" : "",
                  }}
                >
                  <canvas
                    ref={canvasRef}
                    width={pageSize.w}
                    height={pageSize.h}
                    style="background:#eee"
                    tabindex="1"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item sx={{ maxWidth: "50%" }}>
            <Stack spacing={2}>
              <Box>
                <Typography gutterBottom variant="h6">
                  Page Info:
                </Typography>
                <div>
                  URL: <Link href={url}>{url}</Link>
                </div>
                <div>Title: {title}</div>
                <div>Target: {target}</div>
              </Box>
              <Box>
                <Typography gutterBottom variant="h6">
                  Navigation:
                </Typography>
                <Stack spacing={2} direction="row">
                  <ButtonGroup variant="outlined" disabled={isLocked}>
                    <Button
                      onClick={() => {
                        api.say(
                          chatRoomId,
                          JSON.stringify({
                            type: "navigation",
                            target: "top",
                          })
                        );
                      }}
                    >
                      <NorthWestIcon />
                    </Button>
                    <Button
                      onClick={() => {
                        api.say(
                          chatRoomId,
                          JSON.stringify({
                            type: "navigation",
                            target: "scroll",
                            step: -1 * pageSize.s,
                          })
                        );
                      }}
                    >
                      <NorthIcon />
                    </Button>
                    <Button
                      onClick={() => {
                        api.say(
                          chatRoomId,
                          JSON.stringify({
                            type: "navigation",
                            target: "scroll",
                            step: pageSize.s,
                          })
                        );
                      }}
                    >
                      <SouthIcon />
                    </Button>
                    <Button
                      onClick={() => {
                        api.say(
                          chatRoomId,
                          JSON.stringify({
                            type: "navigation",
                            target: "bottom",
                          })
                        );
                      }}
                    >
                      <SouthEastIcon />
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup color="success">
                    <Button
                      onClick={() => {
                        api.say(
                          chatRoomId,
                          JSON.stringify({
                            type: "navigation",
                            target: "back",
                          })
                        );
                      }}
                    >
                      back
                    </Button>
                    <Button
                      onClick={() => {
                        api.say(
                          chatRoomId,
                          JSON.stringify({
                            type: "navigation",
                            target: "forward",
                          })
                        );
                      }}
                    >
                      forward
                    </Button>
                    <Button
                      onClick={() => {
                        api.say(
                          chatRoomId,
                          JSON.stringify({
                            type: "navigation",
                            target: "reload",
                          })
                        );
                      }}
                    >
                      reload
                    </Button>
                    <Button
                      onClick={() => {
                        api.say(
                          chatRoomId,
                          JSON.stringify({
                            type: "navigation",
                            target: "home",
                          })
                        );
                      }}
                    >
                      home
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Box>
              <Box>
                <GrabForm
                  isQueue={queueList.length > 0}
                  isLocked={isLocked}
                  destroyOnEnd={destroyOnEnd}
                  onStart={(type) => {
                    api.say(
                      chatRoomId,
                      JSON.stringify({
                        type: "grab",
                        pageType: type,
                        isDestroy: destroyOnEnd,
                      })
                    );
                  }}
                  onQueue={(type) => {
                    api.say(
                      chatRoomId,
                      JSON.stringify({
                        type: "grab:queue:add",
                        pageType: type,
                        url,
                      })
                    );
                  }}
                  onChangeDestroy={(checked) => {
                    setDestroyOnEnd(checked);
                  }}
                />
              </Box>
              <Box>
                <GrabQueue
                  isLocked={isLocked}
                  destroyOnEnd={destroyOnEnd}
                  queue={queueList}
                  onClear={(cb) => {
                    api.say(
                      chatRoomId,
                      JSON.stringify({
                        type: "grab:queue:clear",
                      })
                    );
                    cb && cb();
                  }}
                  onRemove={(index, cb) => {
                    api.say(
                      chatRoomId,
                      JSON.stringify({
                        type: "grab:queue:remove",
                        index,
                      })
                    );
                    cb && cb();
                  }}
                  onProcess={(cb) => {
                    api.say(
                      chatRoomId,
                      JSON.stringify({
                        type: "grab:queue:process",
                        isDestroy: destroyOnEnd,
                      })
                    );
                    cb && cb();
                  }}
                  onChangeDestroy={(checked) => {
                    setDestroyOnEnd(checked);
                  }}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default SessionDetails;
