//import { getLogger } from "js-simple-logger";
//const logger = getLogger("model:screen:dom");

export class ScreenDomManager {
  dom = {};
  imgUrl = null;
  imgData = null;
  dimension = {};
  idHash = {};

  constructor(dom, imgUrl, options) {
    this.dom = Object.assign({}, dom);
    this.imgUrl = imgUrl;

    //fix parent
    for (const id in this.dom) {
      this.dom[id].id = parseInt(id, 10);
      (this.dom[id].children || []).forEach((childId) => {
        if (this.dom[childId]) {
          this.dom[childId].parent = this.dom[id].id;
        }
      });
    }
    //fix tree id
    let rootCnt = 0;
    for (const id in this.dom) {
      if (!this.dom[id].parent) {
        this.dom[id].tid = `${rootCnt++}`;
      } else {
        this.dom[id].tid = `${this.dom[this.dom[id].parent].tid}/${this.dom[
          this.dom[id].parent
        ].children.indexOf(this.dom[id].id)}`;
      }
      this.idHash[this.dom[id].tid] = this.dom[id].id;
    }
    //    //fix backgroundColor (due to transparent bg)
    //    const emptyBackground = ["rgba(0, 0, 0, 0)"];
    //    for (const id in this.dom) {
    //      const backgroundColor = this.getStyleProperty(id, "background-color");
    //      if (emptyBackground.indexOf(backgroundColor) !== -1) {
    //        let parentId = this.dom[id].parent;
    //        while (parentId) {
    //          const backgroundColor = this.getStyleProperty(
    //            parentId,
    //            "background-color"
    //          );
    //          if (emptyBackground.indexOf(backgroundColor) === -1) {
    //            parentId = null;
    //            this.dom[id].computedStyles["background-color"] = backgroundColor;
    //          } else {
    //            parentId = this.dom[parentId].parent;
    //          }
    //        }
    //      }
    //    }

    this.options = Object.assign(
      {
        screenTopOffset: 0,
        boxModelKey: "border",
      },
      options
    );
  }
  getIdByTreeId(tid) {
    return this.idHash[tid] || null;
  }
  getTreeIdById(id) {
    return this.getNode(id)?.tid || null;
  }
  getNodeByTreeId(tid) {
    return this.getNode(this.getIdByTreeId(tid));
  }
  getNode(id) {
    return id ? this.dom[id] : null;
  }
  getParentId(id) {
    return this.getNode(id)?.parent;
  }
  getParentTreeId(tid) {
    return this.getTreeIdById(this.getParentId(this.getIdByTreeId(tid)));
  }
  getParentNode(id) {
    return this.getNode(this.getParentId(id));
  }
  getChildId(id) {
    const children = this.getNode(id)?.children;
    if (children) {
      for (let i = 0; i < children.length; i++) {
        if (this.isVisible(children[i])) {
          return children[i];
        }
      }
    }
  }
  getChildTreeId(tid) {
    return this.getTreeIdById(this.getChildId(this.getIdByTreeId(tid)));
  }
  getChildNode(id) {
    return this.getNode(this.getChildId(id));
  }
  getSiblingIdLeft(id) {
    let parent = this.getParentNode(id);
    if (!parent) return null;
    let i = parent.children?.indexOf(id);
    while (i > 0) {
      i--;
      if (this.isVisible(parent.children[i])) {
        return parent.children[i];
      }
    }
  }
  getSiblingTreeIdLeft(tid) {
    return this.getTreeIdById(this.getSiblingIdLeft(this.getIdByTreeId(tid)));
  }
  getSiblingIdRight(id) {
    let parent = this.getParentNode(id);
    if (!parent) return null;
    let i = parent.children?.indexOf(id);
    while (i < parent.children?.length - 1) {
      i++;
      if (this.isVisible(parent.children[i])) {
        return parent.children[i];
      }
    }
  }
  getSiblingTreeIdRight(tid) {
    return this.getTreeIdById(this.getSiblingIdRight(this.getIdByTreeId(tid)));
  }
  getSameSiblingIdList(id) {
    const node = this.getNode(id);
    const parent = this.getParentNode(id);
    const idList = [];
    for (let i = 0; i < parent?.children?.length; i++) {
      if (this.isVisible(parent.children[i])) {
        let child = this.getNode(parent.children[i]);
        if (child.name == node.name) {
          idList.push(parent.children[i]);
        }
      }
    }
    return idList;
  }
  getSameSiblingTreeIdList(tid) {
    const idList = this.getSameSiblingIdList(this.getIdByTreeId(tid));
    return idList.map(
      ((id) => {
        return this.getTreeIdById(id);
      }).bind(this)
    );
  }
  getBox(id) {
    const node = this.getNode(id);
    if (node) {
      return (node.boxModel || {})[this.options.boxModelKey];
    }
    return null;
  }
  findIdByCoordinates(x, y) {
    let idList = [];
    for (const id in this.dom) {
      const box = this.getBox(id);
      if (
        box &&
        box[0] <= x &&
        box[2] >= x &&
        box[1] + this.options.screenTopOffset <= y &&
        box[7] + this.options.screenTopOffset >= y &&
        this.isVisible(id) &&
        true
        // TODO isParentInViewPort ? make as option?
        //this.isParentInViewPort(id)
      ) {
        const parentId = this.getParentId(id);
        const parentIndex = idList.indexOf(parentId);
        if (parentIndex != -1) {
          delete idList[parentIndex];
        }
        // if (
        //  !idList.length ||
        //  (parentId && idList.indexOf(parentId) != -1)
        // ) {
        idList.push(this.dom[id].id);
        // }
      }
    }
    // if (idList.length) {
    //  const parentIdList = [...idList];
    // }
    idList = idList.filter((v) => {
      return v != null;
    });
    //logger.debug("findByCoordinates:idList", idList);
    //const id = idList.length > 0 ? idList.pop() : null;
    //const id = idList.length > 0 ? idList[0] : null;

    let id = null;

    if (idList.length) {
      idList.sort((a, b) => {
        return this.getSquare(a) > this.getSquare(b);
      });
      id = idList[0];
    }

    //logger.debug("findByCoordinates:node", this.getNode(id));
    return id;
  }
  isVisible(id) {
    const node = this.getNode(id);
    return !!(
      node &&
      node.computedStyles &&
      node.computedStyles.visibility != "hidden" &&
      node.computedStyles.display != "none" &&
      this.getSquare(id) > 0
    );
  }
  isInViewPort(id) {
    const box = this.getBox(id);
    if (!box || (box[2] > 0 && box[0] < this.options.width)) {
      return true;
    }
    return false;
  }
  isParentInViewPort(id) {
    id = this.getParentId(id);
    while (id) {
      if (!this.isInViewPort(id)) {
        return false;
      }
      id = this.getParentId(id);
    }
    return true;
  }
  findNodeByCoordinates(x, y) {
    return this.getNode(this.findIdByCoordinates(x, y));
  }
  getRectPosition(id, topShift) {
    const box = this.getBox(id);
    if (box) {
      topShift = topShift || 0;
      return [
        box[0],
        box[1] + this.options.screenTopOffset + topShift,
        box[4],
        box[5] + this.options.screenTopOffset + topShift,
      ];
    }
    return null;
  }
  getRectPositionByTreeId(tid) {
    return this.getRectPosition(this.getIdByTreeId(tid));
  }
  getSquare(id) {
    const box = this.getBox(id);
    if (box) {
      return (box[4] - box[0]) * (box[5] - box[1]);
    }
    return Infinity;
  }
  getImageUrl() {
    return this.imgData || this.imgUrl;
  }
  setImageData(data) {
    this.imgData = data;
  }
  getStyleProperty(id, key) {
    return this.getNode(id)?.computedStyles?.[key];
  }
}
