import Text from "./text";

export default function TextProductCategory(props) {
  return (
    <Text
      {...props}
      text={(props?.data?.product?.categoryList || []).join(
        props?.data?.product?.delimiter || " / "
      )}
    />
  );
}
