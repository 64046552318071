import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useState } from "preact/hooks";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export function GrabQueueItem(props) {
  const [isRemoving, setIsRemoving] = useState(false);

  return (
    <>
      <Grid item xs={2}>
        [{props.type || ""}]
      </Grid>
      <Grid item xs={8}>
        <Link href={props.url || ""}>{props.url || ""}</Link>
      </Grid>
      <Grid item xs={2}>
        <ButtonGroup>
          <LoadingButton
            loading={isRemoving}
            disabled={props.isLocked || isRemoving}
            color="error"
            onClick={() => {
              setIsRemoving(true);
              props.onRemove &&
                props.onRemove(props.index, () => {
                  setIsRemoving(false);
                });
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </LoadingButton>
        </ButtonGroup>
      </Grid>
    </>
  );
}
