import { useCallback, useState, useEffect } from "preact/compat";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import ListSubheader from "@mui/material/ListSubheader";

export function ElementRoleSelector(props) {
  const [category, setCategory] = useState(props.roleList?.[0]?.key || "");

  const getSelectCategoryList = useCallback(() => {
    const list = [];
    (props.roleList || []).forEach((item) => {
      list.push(<MenuItem value={item.key}>{item.key}</MenuItem>);
    });
    return list;
  }, [props.roleList]);

  const getSelectItemList = useCallback(() => {
    const list = [];
    (props.roleList || []).forEach((item) => {
      if (item.key == category) {
        // list.push(<ListSubheader>{item.key}</ListSubheader>);
        list.push(
          <MenuItem value={item.key}>
            <strong>{`box`}</strong>
          </MenuItem>,
        );
        (item.children || []).forEach((subItem) => {
          list.push(
            <MenuItem value={`${item.key}:${subItem.key}`}>
              {`${subItem.key}`}
            </MenuItem>,
          );
        });
      }
    });
    return list;
  }, [props.roleList, category]);

  useEffect(() => {
    const c = props.role?.split(":")[0];
    c && setCategory(c);
  }, [props.roleList, props.role]);

  return (
    <>
      <Select
        value={category}
        sx={{ minWidth: 100 }}
        onChange={(e) => {
          setCategory(e.target.value);
        }}
      >
        {getSelectCategoryList().map((el) => {
          return el;
        })}
      </Select>
      <Select
        value={props.role}
        sx={{ minWidth: 120 }}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.value);
        }}
      >
        {getSelectItemList().map((el) => {
          return el;
        })}
      </Select>
    </>
  );
}
