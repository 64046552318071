import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

//import { useState, useEffect } from "preact/compat";

export function LayoutTreeView(props) {
  const getItemList = (role, tidList) => {
    const res = [];
    tidList.forEach((tid) => {
      const el = props.dm?.getItem(tid);
      if (el && el.role === role) {
        res.push(Object.assign({ tid }, el));
      }
    });
    return res;
  };

  const printItemList = (itemList) => {
    return (
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        {itemList.map((item, index) => {
          return (
            <Link
              key={index}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                props.onSelectItem && props.onSelectItem(item.tid, item);
              }}
            >
              {item.tag || item.id}
            </Link>
          );
        })}
      </Stack>
    );
  };

  return (
    <>
      <List sx={{ width: "100%", maxHeight: 450, overflow: "auto" }}>
        {(props.dm?.structure || []).map((item) => {
          const branchItemList = props.dm?.getItemListByRolePrefix(item.key);
          const branchItemIdList = Object.keys(branchItemList);
          if (item.required || branchItemIdList.length) {
            return (
              <ListItemText key={item.key}>
                <Stack
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                >
                  <strong>
                    {item.key}
                    {item.required && <i style="color:'red';">*</i>}
                  </strong>
                  {printItemList(getItemList(item.key, branchItemIdList))}
                </Stack>
                {item.children && (
                  <List component="div" sx={{ pl: 4 }}>
                    {item.children.map((subItem) => {
                      const key = `${item.key}:${subItem.key}`;
                      const itemList = getItemList(key, branchItemIdList);
                      if (subItem.required || itemList.length) {
                        return (
                          <ListItemText key={key}>
                            <Stack
                              direction="row"
                              justifyContent="left"
                              alignItems="center"
                              spacing={2}
                            >
                              <span>
                                {subItem.key}
                                {subItem.required && (
                                  <i style="color:red;">*</i>
                                )}
                              </span>
                              {printItemList(itemList)}
                            </Stack>
                          </ListItemText>
                        );
                      }
                    })}
                  </List>
                )}
              </ListItemText>
            );
          }
        })}
      </List>
    </>
  );
}
