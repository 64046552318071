import Icon from "./icon";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

export default function IconCart(props) {
  return (
    <Icon
      {...props}
      iconList={[
        ShoppingCartIcon,
        ShoppingBasketIcon,
        LocalMallIcon,
        ShoppingBagIcon,
      ]}
    />
  );
}
