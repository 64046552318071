import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export function PaletteView(props) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {(props.list || []).map((rgb, index) => {
        return (
          <Box
            key={index}
            sx={{
              backgroundColor: `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`,
              width: "20px",
              height: "20px",
              border: "1px solid black",
              padding: "2px",
            }}
          />
        );
      })}
    </Stack>
  );
}
