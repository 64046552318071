import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useState } from "preact/hooks";
import { GrabQueueItem } from "./grabQueueItem";
import Divider from "@mui/material/Divider";

export function GrabQueue(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  return (
    <>
      {props?.queue.length > 0 && (
        <>
          <Typography gutterBottom variant="h6">
            Queue:
          </Typography>
          <Divider sx={{ m: 2 }} />
          <Grid
            container
            spacing={2}
            sx={{ maxHeight: 250, overflowY: "auto", width: "100%" }}
          >
            {props?.queue.map((el, index) => {
              return (
                <GrabQueueItem
                  isLocked={props.isLocked}
                  key={index}
                  url={el.url}
                  type={el.type}
                  index={index}
                  onRemove={props.onRemove}
                />
              );
            })}
          </Grid>
          <Divider sx={{ m: 2 }} />
          <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.destroyOnEnd}
                  disabled={props.isLocked}
                  onChange={(e) => {
                    props.onChangeDestroy &&
                      props.onChangeDestroy(e.target.checked);
                    return false;
                  }}
                />
              }
              label="Destroy on End"
            />
            <ButtonGroup variant="outlined">
              <LoadingButton
                variant="outlined"
                color="success"
                loading={isProcessing}
                disabled={props.isLocked || isProcessing || isClearing}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsProcessing(true);
                  props.onProcess &&
                    props.onProcess(() => {
                      setIsProcessing(false);
                    });
                }}
              >
                process
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                color="error"
                loading={isClearing}
                disabled={props.isLocked || isProcessing || isClearing}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsClearing(true);
                  props.onClear &&
                    props.onClear(() => {
                      setIsClearing(false);
                    });
                }}
              >
                clear
              </LoadingButton>
            </ButtonGroup>
          </Stack>
        </>
      )}
    </>
  );
}
