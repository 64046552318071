import { Stack } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import ShareIcon from "@mui/icons-material/Share";

export default function Social(props) {
  const cursor = "pointer";
  const fontSize =
    Math.min(props.height, parseFloat(props.fontSize, 10) * 1.7) || "24";

  let justifyContent = "flex-start";
  switch (props.textAlign) {
    case "center":
      justifyContent = "center";
      break;
    case "end":
      justifyContent = "flex-end";
      break;
  }

  return (
    <Stack
      sx={{
        color: props.color || "rgb(0,0,0)",
        fontFamily: props.fontFamily || "",
        textAlign: props?.textAlign || "left",
        margin: "0, auto",
      }}
      spacing={1}
      direction="row"
      alignItems={"center"}
      justifyContent={justifyContent}
    >
      <ShareIcon sx={{ cursor, fontSize }} />
      <FacebookIcon sx={{ cursor, fontSize }} />
      <TwitterIcon sx={{ cursor, fontSize }} />
      <PinterestIcon sx={{ cursor, fontSize }} />
    </Stack>
  );
}
