import { Stack } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Text from "./text";

export default function TextProductAddToFavorite(props) {
  let fontSize = parseFloat(props.fontSize, 10);
  let isShowText = true;

  if (props.width / props.height < 3) {
    isShowText = false;
    fontSize = Math.max(props.height / 1.5, fontSize);
  }

  return (
    <Stack
      sx={{
        color: props?.color || "rgb(0,0,0)",
        fontFamily: props?.fontFamily || "",
        textAlign: props?.textAlign || "left",
        margin: "0, auto",
        width: "100%",
        height: "100%",
      }}
      spacing={1}
      direction="row"
      alignItems={"center"}
      justifyContent={isShowText ? props.justifyContent || "center" : "center"}
    >
      <FavoriteIcon sx={{ fontSize: `${fontSize}px` }} />
      {isShowText && <Text {...props} text={"Favorite"} />}
    </Stack>
  );
}
