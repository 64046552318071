import { Stack } from "@mui/material";

export default function NotImplemented(props) {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#f00",
        color: "#fff",
        fontFamily: "Roboto",
      }}
      spacing={1}
      direction="column"
      alignItems={"center"}
      justifyContent={"center"}
    >
      {props.type || "unknown"}
    </Stack>
  );
}
