import { Box, Typography } from "@mui/material";

export function ElementColorView(props) {
  return (
    <>
      <Box sx={{
        height: 32,
        width: 32,
        border: 1,
        borderColor: "black",
        backgroundColor: props.params["background-color"],
      }}>
        <Box sx={{
          height: 14,
          width: 14,
          marginLeft: 1,
          marginTop: 1,
          backgroundColor: props.params.color,
        }} />
      </Box>
      <Typography >{ (props.params["font-family"] || "").split(", ")[0].replaceAll('"', '') } / {parseInt(props.params["font-size"])}</Typography>
    </>
  );
}
