import { Stack, Box } from "@mui/material";
import ImageBase from "./image";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RemoveIcon from "@mui/icons-material/Remove";

const iconList = [FiberManualRecordIcon, RemoveIcon];

export default function ImageProductThumbnail(props) {
  const imageList = props?.data?.product?.imageList || [];
  const isShowThumbnail = props.height > 40;
  const dotSize = 20;

  const ThumbIcon = iconList[Math.floor(Math.random() * iconList.length)];

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      justifyContent={isShowThumbnail ? props.justifyContent : "center"}
    >
      {imageList.map((img, i) => {
        if (isShowThumbnail) {
          return (
            <Box
              key={i}
              sx={{
                cursor: "pointer",
                width: props.height,
                height: props.height,
              }}
            >
              <ImageBase {...props} src={img?.src} />
            </Box>
          );
        }
        return (
          <ThumbIcon
            key={i}
            sx={{
              cursor: "pointer",
              fontSize: dotSize,
            }}
          />
        );
      })}
    </Stack>
  );
}
