import Text from "./text";

export default function TextProductCallToAction(props) {
  return (
    <Text
      {...props}
      sx={{ cursor: "pointer" }}
      text={props?.data?.product?.callToAction || "BUY"}
    />
  );
}
