import { Stack, Typography } from "@mui/material";
import Text from "./text";

export default function TextProductTitle(props) {
  const isShowTitle = props.height > 0 && 2 * props.height > props.width;

  return (
    <Stack space={2} alignItems={"center"}>
      {isShowTitle && (
        <Typography
          variant="h4"
          sx={{
            fontFamily: props.fontFamily || null,
            color: props.color,
          }}
        >
          Product Details
        </Typography>
      )}
      <Text {...props} text={props?.data?.product?.description} />
    </Stack>
  );
}
