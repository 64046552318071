import { Stack } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TelegramIcon from "@mui/icons-material/Telegram";
import GoogleIcon from "@mui/icons-material/Google";

export default function Social(props) {
  const cursor = "pointer";

  let fontSize =
    props.height * 7 < props.width
      ? props.height
      : Math.floor((props.width - 6 * 4) / 7);

  fontSize = Math.min(fontSize, 32);

  return (
    <Stack
      sx={{
        color: props.color || "rgb(0,0,0)",
        fontFamily: props.fontFamily || "",
        textAlign: props?.textAlign || "left",
      }}
      spacing={"4px"}
      direction="row"
      alignItems={"center"}
      justifyContent={props.justifyContent || "center"}
    >
      <FacebookIcon sx={{ cursor, fontSize }} />
      <YouTubeIcon sx={{ cursor, fontSize }} />
      <TwitterIcon sx={{ cursor, fontSize }} />
      <InstagramIcon sx={{ cursor, fontSize }} />
      <PinterestIcon sx={{ cursor, fontSize }} />
      <TelegramIcon sx={{ cursor, fontSize }} />
      <GoogleIcon sx={{ cursor, fontSize }} />
    </Stack>
  );
}
