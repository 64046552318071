import Box from "@mui/material/Box";

export default function Text(props) {
  return (
    <Box
      sx={{
        ...(props.sx || {}),
        color: props.color || "rgb(0,0,0)",
        fontSize: props.fontSize || "12px",
        fontFamily: props.fontFamily || "",
        lineHeight: props.lineHeight,
        textAlign: props?.textAlign || "left",
        margin: props.margin,
        pading: props.pading,
      }}
    >
      {props.text}
    </Box>
  );
}
