import quantize from "quantize";

export class ImagePalette {
  imgData = [];
  colorCount = 5;
  quality = 10;

  constructor(imgData, options) {
    let { colorCount, quality } = options || {};

    if (typeof colorCount !== "undefined" && Number.isInteger(colorCount)) {
      this.colorCount = Math.min(Math.max(colorCount, 2), 20);
    }

    if (
      typeof quality !== "undefined" &&
      Number.isInteger(quality) &&
      quality < 1
    ) {
      this.quality = quality;
    }
    if (imgData) {
      this.imgData = imgData;
    }
  }

  createPixelArray() {
    const pixelArray = [];
    let max = this.imgData.width * this.imgData.height;
    for (let i = 0, offset, r, g, b, a; i < max; i = i + this.quality) {
      offset = i * 4;
      r = this.imgData.data[offset + 0];
      g = this.imgData.data[offset + 1];
      b = this.imgData.data[offset + 2];
      a = this.imgData.data[offset + 3];

      // If pixel is mostly opaque and not white
      if (typeof a === "undefined" || a >= 125) {
        //if (!(r > 250 && g > 250 && b > 250)) {
        pixelArray.push([r, g, b]);
        //}
      }
    }
    return pixelArray;
  }

  getPalette() {
    const cmap = quantize(this.createPixelArray(), this.colorCount);
    const palette = cmap ? cmap.palette() : null;
    return palette;
  }
}
