import Stack from "@mui/material/Stack";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { ElementRoleSelector } from "./elementRoleSelector";
import { useState, useEffect } from "preact/compat";

export function ElementRoleForm(props) {
  const [role, setRole] = useState("");
  const [isSaved, setIsSaved] = useState(true);
  const [isDeleted, setIsDeleted] = useState(true);

  useEffect(() => {
    setRole(props.role);
    setIsSaved(true);
    setIsDeleted(!props.role);
  }, [props.role]);

  return (
    <Stack direction="row" spacing={2}>
      <ElementRoleSelector
        roleList={props.roleList}
        role={role}
        onChange={(role) => {
          setRole(role);
          setIsSaved(false);
        }}
      />
      <ButtonGroup variant="outlined">
        <Button
          disabled={!role || (isSaved && !!role)}
          onClick={() => {
            props.onSave && props.onSave([props.tid], role);
            setIsSaved(true);
            setIsDeleted(false);
          }}
        >
          save
        </Button>
        {props.role !== role && (
          <Button
            color="success"
            onClick={() => {
              setRole(props.role);
              setIsSaved(true);
            }}
          >
            reset
          </Button>
        )}
        {role && !isDeleted && (
          <Button
            color="error"
            onClick={() => {
              props.onDelete && props.onDelete([props.tid]);
              setIsSaved(false);
              setIsDeleted(true);
              setRole("");
            }}
          >
            delete
          </Button>
        )}
      </ButtonGroup>

      {props.sameSiblingTreeIdList.length > 1 && (
        <ButtonGroup>
          <Button
            disabled={!role || (isSaved && !!role)}
            onClick={() => {
              props.onSave && props.onSave(props.sameSiblingTreeIdList, role);
              setIsSaved(true);
              setIsDeleted(false);
            }}
          >
            save siblings
          </Button>
          {role && !isDeleted && (
            <Button
              color="error"
              onClick={() => {
                props.onDelete && props.onDelete(props.sameSiblingTreeIdList);
                setIsSaved(false);
                setIsDeleted(true);
                setRole("");
              }}
            >
              delete siblings
            </Button>
          )}
        </ButtonGroup>
      )}
    </Stack>
  );
}
