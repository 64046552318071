import Icon from "./icon";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import FaceIcon from "@mui/icons-material/Face";
import PersonIcon from "@mui/icons-material/Person";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import PersonPinIcon from "@mui/icons-material/PersonPin";

export default function IconProfile(props) {
  return (
    <Icon
      {...props}
      iconList={[
        AccountBoxIcon,
        AccountCircleIcon,
        AssignmentIndIcon,
        FaceIcon,
        PersonIcon,
        PermContactCalendarIcon,
        InsertEmoticonIcon,
        PersonPinIcon,
      ]}
    />
  );
}
