//import { useState } from "preact/compat";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useEffect, useState, useRef, useCallback } from "preact/hooks";
import { DataManagerFactory } from "../../../models/data/factory";
import { typeList } from "../../page/constant";

export function LayoutPreviewParams(props) {
  const [dm, setDm] = useState(null);
  const [type, setType] = useState(props.type);
  const [selected, setSelected] = useState([]);

  const roleSelectRef = useRef();

  const onUpdate = useCallback(
    (selected) => {
      const selectedList = dm.fixRoleListWithParent(selected);
      props.onUpdate &&
        props.onUpdate({
          boxes_type: selectedList,
          box_rel: dm.getRoleRelation(selectedList),
        });
    },
    [props, dm]
  );

  useEffect(() => {
    if (dm) {
      const selectedList = dm.getRoleDefaultSelected();
      setSelected(selectedList);
      onUpdate(selectedList);
    }
  }, [dm]);

  // useEffect(() => {
  //   if (dm && props.onUpdate) {
  //     // calculate params
  //     const selectedList = dm.fixRoleListWithParent(selected);
  //     props.onUpdate({
  //       boxes_type: selectedList,
  //       relations: dm.getCompactRoleRelation(selectedList),
  //     });
  //   }
  // }, [dm, selected, props.onUpdate]);

  useEffect(() => {
    if (type) {
      const dmInstance = DataManagerFactory.get(type);
      setDm(dmInstance);
    }
  }, [type]);

  return (
    <Stack
      direction="column"
      spacing={0}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <FormControl sx={{ m: 2, minWidth: 300 }}>
        <InputLabel shrink htmlFor="select-type">
          Type
        </InputLabel>
        <Select
          native
          defaultValue={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
          label="Type"
          inputProps={{
            id: "select-type",
          }}
        >
          {typeList.map((el) => {
            // TODO: temp restriction
            if (el.key !== "pdp") return;
            return (
              <option key={el.key} value={el.key}>
                {el.title}
              </option>
            );
          })}
        </Select>
      </FormControl>
      {dm && (
        <FormControl
          sx={{
            m: 2,
            minHeight: 600,
            minWidth: 300,
          }}
        >
          <InputLabel shrink htmlFor="select-component-list" sx={{ mt: -0.8 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <>Components</>
              <ButtonGroup size="small">
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    const selectedList = dm.getRoleList();
                    setSelected(selectedList);
                    onUpdate(selectedList);
                    setTimeout(() =>
                      roleSelectRef?.current?.firstChild.scroll(0, 0)
                    );
                  }}
                >
                  <RadioButtonCheckedIcon />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    const selectedList = [];
                    setSelected(selectedList);
                    onUpdate(selectedList);
                    setTimeout(() =>
                      roleSelectRef?.current?.firstChild.scroll(0, 0)
                    );
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
                <IconButton
                  color="success"
                  onClick={(e) => {
                    e.stopPropagation();
                    const selectedList = dm.getRoleDefaultSelected();
                    setSelected(selectedList);
                    onUpdate(selectedList);
                    setTimeout(() =>
                      roleSelectRef?.current?.firstChild.scroll(0, 0)
                    );
                  }}
                >
                  <RestartAltIcon />
                </IconButton>
              </ButtonGroup>
            </Stack>
          </InputLabel>
          <Select
            ref={roleSelectRef}
            multiple
            native
            defaultValue={selected}
            onChange={(e) => {
              const selectedList = Object.keys(e.target.selectedOptions).map(
                (k) => {
                  return e.target.selectedOptions[k].value;
                }
              );
              setSelected(selectedList);
              onUpdate(selectedList);
            }}
            label="Components"
            inputProps={{
              id: "select-component-list",
              size: 22,
              sx: {
                margin: 1,
              },
            }}
          >
            {dm.structure &&
              dm.structure.map((el) => {
                return (
                  <optgroup key={el.key} label={el.key}>
                    {el.children &&
                      el.children.map((sel) => {
                        const skey = `${el.key}:${sel.key}`;
                        return (
                          <option
                            key={skey}
                            value={skey}
                            selected={selected.indexOf(skey) !== -1}
                          >
                            {skey}
                          </option>
                        );
                      })}
                  </optgroup>
                );
              })}
          </Select>
        </FormControl>
      )}
    </Stack>
  );
}
