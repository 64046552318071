import { atom } from "recoil";
import storageEffect from "./effects/storage";

const pdWidgetState = atom({
  key: "pd-widget-state",
  default: {el: true},
  effects: [storageEffect("pd-widget-state")],
});

export default pdWidgetState;
