import { BasicDataManager } from "./basicManager";
import { PdpDataManager } from "./pdpDataManager";

export class DataManagerFactory {
  static get(type, ...args) {
    switch (type) {
      case "pdp":
        return new PdpDataManager(args);
      default:
        return new BasicDataManager(args);
    }
  }
}
