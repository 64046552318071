import { atom } from "recoil";
import storageEffect from "./effects/storage";

const tokenState = atom({
  key: "token",
  default: null,
  effects: [storageEffect("token")],
});

export default tokenState;
