import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { targetList } from "../../page/constant.js";

const defaultUrl = "";

export default function CreateFormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState(defaultUrl);
  const [target, setTarget] = React.useState("");
  const [isCreating, setIsCreating] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isCreating) {
      setUrl(defaultUrl);
      // setTarget("");
      setOpen(false);
    }
  };

  const handleCreate = () => {
    setIsCreating(true);
    if (props.api) {
      props.api.action(
        "sessionCreateList",
        { target, urlList: url.trim().split("\n") },
        (data) => {
          if (data.ok) {
            setIsCreating(false);
            setOpen(false);
            handleClose();
            props.onCreate && props.onCreate(data.id);
          }
          setIsCreating(false);
        }
      );
    } else {
      setIsCreating(false);
    }
  };

  return (
    <div>
      <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Session</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="URL(s)"
              type="url"
              fullWidth
              multiline
              variant="standard"
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              value={url}
            />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
            <InputLabel id="demo-select-small">Target</InputLabel>
            <Select
              label="Target"
              onChange={(e) => {
                setTarget(e.target.value);
              }}
              value={target}
            >
              {targetList.map((v) => (
                <MenuItem key={v.key} value={v.key}>
                  {v.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <LoadingButton disabled={isCreating} onClick={handleClose}>
            Cancel
          </LoadingButton>
          <LoadingButton loading={isCreating} onClick={handleCreate}>
            Start
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
