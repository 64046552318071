export default function Icon(props) {
  const iconList = props.iconList || [];
  const RandomIcon = iconList[Math.floor(Math.random() * iconList.length)];

  let size = props.width > props.height ? props.height : props.width;
  size = props.fontSize
    ? Math.min(parseInt(props.fontSize, 10) * 1.5, size)
    : size;
  const marginTop = Math.max(0, (props.height - size) / 2);
  const marginLeft = Math.max(0, (props.width - size) / 2);

  return (
    <RandomIcon
      sx={{
        color: props.color || "rgba(0,0,0,0)",
        marginTop: `${marginTop}px`,
        marginLeft: `${marginLeft}px`,
        fontSize: size || "24",
      }}
    />
  );
}
