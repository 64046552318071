import Stack from "@mui/material/Stack";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useState } from "preact/compat";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export function GrabForm(props) {
  const [type, setType] = useState("pdp");

  return (
    <>
      <Typography gutterBottom variant="h6">
        Grabber:
      </Typography>

      <FormControl
        disabled={props.isLocked}
        sx={{ m: 0, minWidth: "100%" }}
        size="small"
      >
        <Stack direction="row" spacing={0}>
          <InputLabel id="demo-select-small">Page Type</InputLabel>
          <Select
            label="Page Type"
            onChange={(e) => {
              setType(e.target.value);
            }}
            value={type}
          >
            <MenuItem value={"sfp"}>[SFP] Store Front</MenuItem>
            <MenuItem value={"cpp"}>[CPP] Category Promo</MenuItem>
            <MenuItem value={"clp"}>[CLP] Category Listing</MenuItem>
            <MenuItem value={"ppp"}>[PPP] Product Promo</MenuItem>
            <MenuItem value={"pdp"}>[PDP] Product Details</MenuItem>
          </Select>
          <ButtonGroup sx={{ ml: 2, mr: 2 }}>
            <LoadingButton
              variant="outlined"
              disabled={props.isLocked}
              onClick={() => {
                if (!type) return;
                props.onQueue && props.onQueue(type);
              }}
            >
              queue
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              color="success"
              disabled={props.isLocked}
              loading={props.isLocked}
              onClick={() => {
                if (!type) return;
                props.onStart && props.onStart(type);
              }}
            >
              start
            </LoadingButton>
          </ButtonGroup>
          {!props.isQueue && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.destroyOnEnd}
                  disabled={props.isLocked}
                  onChange={(e) => {
                    props.onChangeDestroy &&
                      props.onChangeDestroy(e.target.checked);
                    return false;
                  }}
                />
              }
              label="Destroy on End"
            />
          )}
        </Stack>
      </FormControl>
    </>
  );
}
