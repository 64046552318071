import { v4 as uuidv4 } from "uuid";
import { Compressor } from "./modifiers/compressor";

export class BasicDataManager {
  structure = [];

  typeList = {
    box: {
      type: 1,
      color: "#2C699A",
    },
    image: {
      type: 2,
      color: "#54478C",
    },
    button: {
      type: 4,
      color: "#692C9A",
    },
    text: {
      type: 5,
      color: "#048BA8",
    },
  };

  initUuid = null;
  initItemList = {};

  uuid = null;
  itemList = {};
  processedItemList = {};

  initMeta = {};
  meta = {};

  setInitMeta(meta) {
    this.initMeta = Object.assign({}, meta);
    this.meta = Object.assign({}, meta);
  }

  setMeta(meta) {
    this.meta = Object.assign({}, meta);
  }

  setMetaByKey(k, v) {
    this.meta[k] = v
    this.updateUUID();
  }

  getMetaByKey(k) {
    return this.meta[k]
  }

  onUpdateList() {
    this.updateUUID();
    const c = new Compressor(this.itemList, this.structure);
    c.process();
    this.processedItemList = Object.assign({}, c.itemList);
  }

  updateUUID() {
    this.uuid = uuidv4();
  }

  setInitItemList(itemList) {
    this.initItemList = Object.assign({}, itemList);
    this.itemList = Object.assign({}, itemList);
    this.onUpdateList();
    this.initUuid = this.uuid;
  }

  setItemList(itemList) {
    this.itemList = Object.assign({}, itemList);
    this.onUpdateList();
  }

  isUpdated() {
    return this.initUuid === this.uuid;
  }

  hasItem(tid) {
    return !!this.itemList[tid];
  }

  getItem(tid) {
    return this.itemList[tid];
  }

  setItem(tid, data) {
    // remove exists items by role and its quantity
    const structure = this.getStructureByRole()[data.role];
    if (structure?.quantity) {
      const existsIdList = Object.keys(this.getItemListByRole(data.role) || {});
      existsIdList.slice(structure.quantity - 1).forEach((tid) => {
        this.removeItem(tid);
      });
    }
    this.itemList[tid] = Object.assign({}, data);
    this.onUpdateList();
  }

  removeItem(tid) {
    this.hasItem(tid) && delete this.itemList[tid];
    this.onUpdateList();
  }

  getItemListByRolePrefix(rolePrefix, itemList) {
    const res = {};
    if (!itemList) {
      itemList = this.itemList;
    }
    Object.keys(itemList).forEach((tid) => {
      if (itemList[tid].role && itemList[tid].role.indexOf(rolePrefix) === 0) {
        res[tid] = itemList[tid];
      }
    });
    return res;
  }

  getItemListByRole(role, itemList) {
    const res = {};
    if (!itemList) {
      itemList = this.itemList;
    }
    Object.keys(itemList).forEach((tid) => {
      if (itemList[tid].role && itemList[tid].role == role) {
        res[tid] = itemList[tid];
      }
    });
    return res;
  }

  getStructureByRole() {
    if (!this.structureByRole) {
      this.structureByRole = {};
      for (let i = 0; i < this.structure.length; i++) {
        const item = Object.assign({}, this.structure[i]);
        delete item.children;
        item.level = 0;
        this.structureByRole[item.key] = item;
        for (let j = 0; j < this.structure[i].children.length; j++) {
          const subItem = Object.assign({}, this.structure[i].children[j]);
          subItem.level = 1;
          subItem.parentKey = item.key;
          this.structureByRole[`${item.key}:${subItem.key}`] = subItem;
        }
      }
    }
    return this.structureByRole;
  }

  getStructureItemByRole(role) {
    return this.getStructureByRole()?.[role] || null;
  }

  getRoleList(filter) {
    const list = [];
    this.structure.forEach((el) => {
      (el.children || []).forEach((sel) => {
        if (!filter || filter(sel)) {
          list.push(`${el.key}:${sel.key}`);
        }
      });
    });
    return list;
  }

  getRoleDefaultSelected() {
    return this.getRoleList((el) => {
      return el.required;
    });
  }

  fixRoleListWithParent(roleList) {
    const fixedRoleList = [];
    let parentRole = null;
    (roleList || []).forEach((r) => {
      const roleInfo = this.getStructureItemByRole(r);
      if (
        roleInfo.parentKey &&
        parentRole != roleInfo.parentKey &&
        fixedRoleList.indexOf(roleInfo.parentKey) === -1
      ) {
        parentRole = roleInfo.parentKey;
        fixedRoleList.push(parentRole);
      }
      fixedRoleList.push(r);
    });
    return fixedRoleList;
  }

  getRoleRelation(roleList) {
    let prevParentIndex = -1;
    const relationList = [];
    (roleList || []).forEach((role, i) => {
      const roleInfo = this.getStructureItemByRole(role);
      if (roleInfo?.parentKey) {
        relationList.push([i, 2, roleList.indexOf(roleInfo.parentKey)]);
      } else if (prevParentIndex !== -1) {
        relationList.push([i, 1, prevParentIndex]);
        relationList.push([prevParentIndex, 1, i]);
        prevParentIndex = i;
      } else {
        prevParentIndex = i;
      }
    });
    return relationList;
  }

  getCompactRoleRelation(roleList) {
    let prevParentIndex = -1;
    const relationList = [];
    (roleList || []).forEach((role, i) => {
      const roleInfo = this.getStructureItemByRole(role);
      if (roleInfo?.parentKey) {
        relationList.push([2, roleList.indexOf(roleInfo.parentKey)]);
      } else if (prevParentIndex !== -1) {
        relationList.push([1, prevParentIndex]);
        prevParentIndex = i;
      } else {
        relationList.push([]);
        prevParentIndex = i;
      }
    });
    return relationList;
  }
}
